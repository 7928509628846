import React, { useEffect, useRef, useMemo , useState, useContext } from 'react';

import TableContainer from './Table_material-react-table';
//import gql from 'graphql-tag';
import {Container, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, FormGroup, Row, Alert, Col, Input, Button, Badge, Label } from 'reactstrap';
import UserContext from '../componets/userContext';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
//import { useLazyQuery } from 'react-apollo';
//import {useQuery} from 'react-apollo';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';

import { esES } from '@mui/material/locale';
import * as Icon from 'react-bootstrap-icons';
import { SelectColumnFilter } from './filters';

import { createRow, MaterialReactTable, useMaterialReactTable, MRT_ShowHideColumnsButton,
  MRT_EditActionButtons, MRT_ToggleFullScreenButton, MRT_ToggleDensePaddingButton} from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { NumericFormat } from 'react-number-format';

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from 'react-bootstrap/Modal';
import BuscarPersonaPage from './Page_buscarPersona';
import BuscarArticuloPage from './Page_buscarArticulo';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import "../css/styles.css";

//Material-UI Imports
import {
  Box,
  
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  ListItemIcon,
  MenuItem,
  Typography,
  TextField,
  Stack
} from '@mui/material';

const Ventas = () => {
    const usuario = useContext(UserContext)  
    const persona = usuario.fkpersona.pk_persona;
    let nomApel = '';
    nomApel = usuario.fkpersona.Nombre + ' ' + usuario.fkpersona.Apellido;
    let BotonBuscarPersonaDisable = false;

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [cargarGrilla, setcargarGrilla] = useState(false);
    const [showBuscarPersona, setshowBuscarPersona] = useState(false);
    const [showBuscarArticulo, setshowBuscarArticulo] = useState(false);
    const handleCloseBuscarPersona = () => {
        setshowBuscarPersona(false);
        console.log("LLEGO" + datoAgregar)
    };
    const handleCloseBuscarArticulo = () => {
      console.log("LLEGO" + datoAgregar)
      setshowBuscarArticulo(false);
      
    };
    const [disablebtnPersona, setDisablebtnPersona] = React.useState(BotonBuscarPersonaDisable);

    const [idpersona, setidpersona] = useState(persona);
    const [nombreyapellido, setnombreyapellido] = useState(nomApel);

    const [idstock, setidstock] = useState(0);

    const inicialdata = [
      { id:1, cantidad: 1, descripcion: 'New Item', precio: 50000, total: 50000, sucursal: 'ASU'},
      { id:2, cantidad: 2, descripcion: 'New Item', precio: 50000, total: 50000, sucursal: 'ASU'},
      { id:3, cantidad: 3, descripcion: 'New Item', precio: 50000, total: 50000, sucursal: 'ASU'},
      { id:4, cantidad: 4, descripcion: 'New Item', precio: 50000, total: 50000, sucursal: 'ASU'}];
      
    const [datos, setDatos] = useState([]);
    const [datoAgregar, setdatoAgregar] = useState();

    const [idfila, setIdfila] = useState(1);
//{ cantidad: 1, descripcion: 'New Item', precio: 50000, total: 50000, sucursal: 'ASU'}
    const [MensajeError, setMensajeError] = useState("");

    
    const [datosAl, setDatosAl] = useState([]);
    //const [originalData, setoriginalData] = useState([]);
    // declaramos la bandera de edición
    //const [editable, setEditable] = useState(false);
    const editable= false;
    //const [usuario, setUsuario] = useState([]);
    const [skipPageReset, setSkipPageReset] = React.useState(false);

    const [datosInsti, setDatosInsti] = useState([]);
    const [instituciontext, setinstituciontext] = useState("Institución");
    const [institucion, setinstitucion] = useState(3);
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    const [datosSede, setDatosSede] = useState([]);
    const [sedetext, setsedetext] = useState("Asunción");
    const [sede, setsede] = useState(1);
    const [dropdownOpenSede, setOpenSede] = useState(false);
    const toggleSede = () => setOpenSede(!dropdownOpenSede);

    const [datosTipopago, setDatosTipopago] = useState([]);
    const [tipopagotext, settipopagotext] = useState("Forma pago");
    const [tipopago, settipopago] = useState(1);
    const [dropdownOpenTipopago, setOpenTipopago] = useState(false);
    const toggleTipopago = () => setOpenTipopago(!dropdownOpenTipopago);

    const [datosTipocomprobante, setDatosTipocomprobante] = useState([]);
    const [Tipocomprobante, setTipocomprobante] = useState(1);
    const [Tipocomprobantetext, setTipocomprobantetext] = useState("Tipo comprobante");

    const [datosTipomov, setDatosTipomov] = useState([]);
    const [tipomov, settipomov] = useState();
    
    const togglereporte = () => setdropdownOpenreporte(!dropdownOpenreporte);
    const [dropdownOpenreporte, setdropdownOpenreporte] = useState(false);
    const [reportetextSaldos, setreportetextSaldos] = useState("Con saldo");
    const [estadoCuenta, setestadoCuenta] = useState(1);

    const toggleIE = () => setdropdownOpenIE(!dropdownOpenIE);
    const [dropdownOpenIE, setdropdownOpenIE] = useState(false);
    const [reportetextIE, setreportetextIE] = useState("Ingresos");
    const [ingresosEgresos, setingresosEgresos] = useState(1);

    const [checked, setChecked] = React.useState(true);
    const [checkResponsabletext, setCheckResponsabletext] = React.useState("Imprimir a nombre del responsable");

    const [validationErrors, setValidationErrors] = useState({});
    const [movimiento, setMovimiento] = useState(0);

    const [total, setTotal] = useState("0");

    const [valueComp, setValueComp] = React.useState("");
    
    //const inputRef = useRef();


    const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    function ObtenerDatosTipoMov ()  {
      const Query = gql` query obtenerDatos {
        allTipomovimiento {
          pk_TipoMovimiento
          Descripcion
        }
      }`;
      
      const { loading, error, data } = useQuery(Query,
        {fetchPolicy: "network-only" });
         useEffect(() => {
          if(data) {
            const datostm = data.allTipomovimiento;
            //console.log("datostm");
            //console.log(datostm);
            setDatosTipomov(datostm);
          }
        }, [data]);
    
      if (loading) return 'Loading...';
      
      if (error) {
        console.log(error.message)
        return (
          <Alert color="danger">
            {error.message}
          </Alert>
        )
      }
    } 
    
    ObtenerDatosTipoMov();

function ObtenerDatosTipopago ()  {
  const Query = gql` query obtenerDatos { 
    allTipopago {
      Pk_TipoPago
      Descripcion
      Coeficiente
      PorcentajeARestarDelDescuento
    }}`;
  
  const { loading, error, data } = useQuery(Query,
    {fetchPolicy: "network-only" });
     useEffect(() => {
      if(data) {
        const datos1 = data.allTipopago;
        //console.log(datos1[0].Descripcion);
        settipopago(datos1[0].Pk_TipoPago);
        settipopagotext(datos1[0].Descripcion + " " + datos1[0].PorcentajeARestarDelDescuento + "%");
        //console.log(tipopago);
        setDatosTipopago(datos1);
      }
    }, [data]);

  if (loading) return 'Loading...';
  
  if (error) {
    console.log(error.message)
    return (
      <Alert color="danger">
        {error.message}
      </Alert>
    )
  }
} 

//btenerDatosTipopago();

function ObtenerDatosTipocomprobante ()  {
  const Query = gql` query obtenerDatos { 
    listaTipomprobante {
      Pk_TipoFac
      Descripcion
    }}`;
  
  const { loading, error, data } = useQuery(Query,
    {fetchPolicy: "network-only" });
     useEffect(() => {
      if(data) {
        const datos1 = data.listaTipomprobante;
        //console.log(datos1[0].Descripcion);
        setTipocomprobante(datos1[0].Pk_TipoFac);
        setTipocomprobantetext(datos1[0].Descripcion);
        //console.log(datos1);
        setDatosTipocomprobante(datos1);
      }
    }, [data]);

  if (loading) return 'Loading...';
  
  if (error) {
    console.log(error.message)
    return (
      <Alert color="danger">
        {error.message}
      </Alert>
    )
  }
} 

ObtenerDatosTipocomprobante();


const MostrarComboTipopago = () => {
  const handleClickTipopago = (e) => {
    setMensajeError("");
    settipopagotext(e.target.innerText);
    //console.log(e.target.value)
    var wvalor =  parseInt(e.target.value);
    if (wvalor) {
        settipopago(wvalor);
          //ObtenerDatosAnioLectivo();
    }  
    //loadAsignaturas();
  } 
  return (
    <ButtonDropdown isOpen={dropdownOpenTipopago} toggle={toggleTipopago} className="my-dropdown" >
        <DropdownToggle caret color="success">
          {tipopagotext}
        </DropdownToggle>          
        <DropdownMenu >
                {datosTipopago.map(item => (
                    <DropdownItem 
                        key={item.Pk_TipoPago}
                        value={item.Pk_TipoPago}
                        onClick={handleClickTipopago}>
                      {item.Descripcion} {item.PorcentajeARestarDelDescuento} %
                    </DropdownItem>
                ))}
        </DropdownMenu>
    </ButtonDropdown>
  )
}

const {
  data: fetchedUsers = [],
  
} = useGetUsers();

//READ hook (get users from api)
function useGetUsers() {

      return Promise.resolve(datos);

}

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
});

  const textField = {
    width: 200,
    fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: '#504572',
  }
  const toDay= new Date().toISOString().substring(0, 10);

  async function handleSaveMensaje() {
    setshowBuscarPersona(false);
  }

  async function cerrarBuscarArticulo() {
    
    // const data = 
    //   { id: idfila, id_stock: idstock, cantidad: 1, descripcion: 'New Item', precio: 50000, total: 100000, sucursal:'ASU'}
    // ;
    // //setdatoAgregar({ id: idfila, id_stock: idstock, cantidad: 1, descripcion: 'New Item', precio: 50000, total: 100000, sucursal:'ASU'});
    // console.log(datoAgregar)
    
    setshowBuscarArticulo(false);
  }

  useEffect(() => {
    if(datoAgregar) {
      setDatos([...datos, datoAgregar])
      setIdfila(idfila+1);
      cerrarBuscarArticulo();
    }
  }, [datoAgregar]);

  const totalInscriptos = useMemo(
    () => datos.reduce((acc, curr) => acc + curr.total, 0),
    [datos],
  );

  const columns = useMemo(() => {

    const MostrarComboTipoMov = (datosTipomov) => {
      //(datosTipomov);
      const [dropdownOpenTM, setOpenTM] = useState(false);
      const toggleTM = () => setOpenTM(!dropdownOpenTM);
      const [tipomovtext, settipomovtext] = useState("Contado");

      const handleClick = (e) => {
          setMensajeError("");
          settipomovtext(e.target.innerText);
          //console.log(e.target.value)
          var wvalor =  parseInt(e.target.value);
          if (wvalor) {
            settipomov(wvalor);
            //ObtenerDatosAnioLectivo();
          }  
          //loadAsignaturas();
      } 
      //console.log(datosTipomov);
      return (
          <ButtonDropdown isOpen={dropdownOpenTM} toggle={toggleTM} className="my-dropdown" >
              <DropdownToggle caret color="info" >
                  {tipomovtext}
              </DropdownToggle>          
              <DropdownMenu >
                  {datosTipomov.map(item => (
                      <DropdownItem 
                            key={item.pk_TipoMovimiento}
                            value={item.pk_TipoMovimiento}
                            onClick={handleClick}
                            >
                          {item.Descripcion}
                      </DropdownItem>
                  ))}
              </DropdownMenu>
          </ButtonDropdown>
      )
    }

    const MostrarComboTipocomprobante = (datosTipocomprobante) => {
      
      const [dropdownOpenTipocomprobante, setOpenTipocomprobante] = useState(false);
      const toggleTipocomprobante = () => setOpenTipocomprobante(!dropdownOpenTipocomprobante);
  
      const handleClick = (e) => {
        setMensajeError("");
        setTipocomprobantetext(e.target.innerText);
        //console.log(e.target.value)
        var wvalor =  parseInt(e.target.value);
        if (wvalor) {
          setTipocomprobante(wvalor);
              //ObtenerDatosAnioLectivo();
        }  
        //loadAsignaturas();
      } 
      return (
        <ButtonDropdown isOpen={dropdownOpenTipocomprobante} toggle={toggleTipocomprobante} className="my-dropdown" >
            <DropdownToggle caret color="success">
              {Tipocomprobantetext}
            </DropdownToggle>          
            <DropdownMenu >
                    {datosTipocomprobante.map(item => (
                        <DropdownItem 
                            key={item.Pk_TipoFac}
                            value={item.Pk_TipoFac}
                            onClick={handleClick}>
                          {item.Descripcion}
                        </DropdownItem>
                    ))}
            </DropdownMenu>
        </ButtonDropdown>
      )
    }
    
    const UpdateRow = async (rowid) => {
      
      console.log(rowid);
            let temp = datos.slice()
            console.log(temp[rowid].total);
            temp[rowid].total = 1;
            setDatos(temp);
            console.log(datos);
      //setDatos([...datos, { cantidad: 2, descripcion: 'New Item', precio: 50000, total: 100000, sucursal:'ASU'}])
      //setDatos([...datos, data])
      
    };

    const actualizarRow = (rowIndex, columnId, value) => {
      // We also turn on the flag to not reset the page
      //setSkipPageReset(true)
      //console.log(rowIndex);
      setDatos(old =>
        old.map((row, index) => {
          if (index == rowIndex) {
            //console.log("entro")
            //console.log(columnId)
            //  if (columnId === 'Hemisphere'){
            //   if (isNaN(value)) {
            //     return {
            //       ...old[rowIndex],
            //       [columnId]: value+"prueba",
            //     }
            //   }
            //  }
            return {
              ...old[rowIndex],
              [columnId]: value,
            }
          }
          return row
        })
      )
    }
    const mystyle = {
      //color: "white",
      width: "110px",
      //backgroundColor: "DodgerBlue",
      padding: "6px",
      fontSize: "16px",
      textAlign: "right"
      //fontFamily: "Arial"
    };

    const EditableCell = ({
      cell,  
      row
    }) => {
    
      // We need to keep and update the state of the cell normally
      const [value, setValue] = React.useState(cell.getValue());
      //console.log("valor inicial" + value);

      const [nombreError, setNombreError] = React.useState('');
      
      // const onChange = (event) => {
      //   console.log(event.target.value);
      //   setValue(event.target.value)
      //   calcular columna total
      //   let valor = datos[row.id].cantidad * event.target.value;
      //   actualizarRow(row.id, "total", valor);
      // }
      const onChange = (values) => {
        //console.log(event.target.value);
        //console.log(values, sourceInfo);
        
        setValue(values.value)
        //calcular columna total
        let valor = datos[row.index].cantidad * values.value;
        actualizarRow(row.index, "total", valor);
      }
      //Onblur se activa cuando el usuario retira el foco de la aplicación de un elemento de la página
      // We'll only update the external data when the input is blurred
      const onBlur = (values) => {
        //if (nombreError==='') {
          //console.log(cell.column.id);
          actualizarRow(row.index, cell.column.id, value);
        //}
        setNombreError('');
      }
      
      //{new Intl.NumberFormat("es-ES").format(cell.getValue())}
      //return <Input type="text" onChange={onChange} onBlur={onBlur} value={(value)} />;
      return <NumericFormat style={mystyle} value={(value)} onValueChange={onChange} onBlur={onBlur} decimalSeparator="," thousandSeparator="." allowNegative ={false} isAllowed={(values) => {
        const {floatValue} = values;
        return floatValue >= 1;
      }}/>;
    }

    const arrayColumnas =  [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        size: 40,
        Edit: () => null,
      },
      {
        accessorKey: 'id_stock',
        header: 'IdStock',
        enableEditing: false,
        size: 40,
        Edit: () => null,
      },
      {
        header: 'Cantidad',
        accessorKey: 'cantidad',
        
        size: 100,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        //enableEditing: true,
        //Edit: () => null,
        // muiEditTextFieldProps: {
        //   required: true,
        //   type: 'number',
        //   variant: 'outlined',
        // },
        muiEditTextFieldProps: ({ cell, row, columnId }) => ({
          error: !!validationErrors.cantidad, //highlight mui text field red error color
          helperText: validationErrors.cantidad, //show error message in helper text.
          required: true,
          type: 'number',
          variant: 'outlined',
          inputProps: {min:1,style: { textAlign: "center" }},
          
          onChange: (event) => {
            const value = event.target.value;
            //validation logic
            if (!value) {
              setValidationErrors((prev) => ({ ...prev, cantidad: 'Requirido' }));
            } else if (value < 0) {
              setValidationErrors({
                ...validationErrors,
                cantidad: '> 0',
              });
            } else {
              delete validationErrors.cantidad;
              setValidationErrors({ ...validationErrors });
            }

            //console.log(row);
            
            //console.log(datos);

            //UpdateRow(row.id);
            //Guardar el valor
            actualizarRow(row.index, cell.column.id, value);
            
            //calcular total    
            //console.log("precio u " + datos[row.index].precio);
            let valor = datos[row.index].precio * value;
            actualizarRow(row.index, "total", valor);
          },
        }),
        Footer: () => (
          <FormGroup >
            <Col style={{paddingBottom: '4px' }}>
              {MostrarComboTipoMov(datosTipomov)}
            </Col>
            <Col style={{paddingBottom: '4px' }}>
              {MostrarComboTipocomprobante(datosTipocomprobante)}
            </Col>
            {/* <Col >
              {MostrarComboTipopago()}
            </Col> */}
          </FormGroup>
        ),
      },
      
      {
        header: 'Descripción',
        accessorKey: 'descripcion',
        size:380,
        enableEditing: false,
        Edit: () => null,
        muiEditTextFieldProps: {
          required: true,
          variant: 'outlined',
        },
        //Edit: ({ cell, renderedCellValue }) => <>{renderedCellValue}</>, //render the normal cell value instead of a text field
        
      },
      {
        header: 'Precio U.',
        accessorKey: 'precio',
        size: 100,
        //enableEditing: true,
        // Cell: ({ cell }) =>
        //   cell
        //     .getValue()
        //     .toLocaleString('es-ES'),

        Edit: EditableCell
        //Edit: () => null,
        // muiTableHeadCellProps: {
        //   align: 'center',
        // },
        // muiTableBodyCellProps: {
        //   //align: 'right',
        //   align: 'center',
        // },
        //enableEditing: true, //disable editing on this column
        //Edit: () => null,

        // muiEditTextFieldProps: ({ cell, row }) => ({
        //   error: !!validationErrors.precio, //highlight mui text field red error color
        //   helperText: validationErrors.precio, //show error message in helper text.
        //   required: true,
        //   type: 'number',
        //   variant: 'outlined',
        //   //thousandSeparator: ".",

        //   value: cell.getValue(),
        //   onChange: (event) => {
        //     const value = event.target.value;
        //     //validation logic
        //     if (!value) {
        //       setValidationErrors((prev) => ({ ...prev, precio: 'Requirido' }));
        //     } else if (value <= 0) {
        //       setValidationErrors({
        //         ...validationErrors,
        //         precio: '> 0',
        //       });
        //     } else {
        //       delete validationErrors.precio;
        //       setValidationErrors({ ...validationErrors });
        //     }
        //     //calcular total
        //     //console.log(datos);
        //     //UpdateRow(row.id);
        //     //Guardar el valor
        //     actualizarRow(row.id, cell.column.id, value);

        //     //calcular total
        //     let valor = datos[row.id].cantidad * value;
        //     actualizarRow(row.id, "total", valor);
            
        //   },
          
        // }),
      },
      {
        accessorKey: 'total',
        //accessorFn: (row) => row.cantidad * row.precio,
        header: 'Total',
        enableEditing: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        
        //enableEditing: true, //disable editing on this column
        //Edit: () => null,
        Cell: ({ cell }) =>  <Box sx={{ color: 'primary.main', fontSize: 16, display: 'inline', fontWeight: 'bold' }}>{new Intl.NumberFormat("es-PY").format(cell.getValue())}</Box>,
        //Cell: ({ cell }) => formatMoney(cell.getValue()),
        //Cell: ({ cell }) => <div style={{ textAlign: "center" }}>{cell.getValue()}</div>,
        Footer: () => (
          <FormGroup >
            <Col style={{paddingBottom: '4px' }}>
              <Input type="text" className='text-success' style={{ fontSize: "18px", textAlign: "center", fontWeight: 'bold', }} sx={{ width: 220 }} disabled 
                value={totalInscriptos?.toLocaleString?.('es-PY')}>
              </Input>
            </Col>
          </FormGroup>
        ),
      },
      {
        accessorKey: 'sucursal',
        header: 'Sucursal',
        size: 40,
        enableEditing: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      // {
      //   header: 'Exentas',
      //   accessorKey: 'curso',
      //   //enableColumnActions: false,
      //   //accessorFn: (row) => row?.curso.Aranceles?.Descripcion, //Muestra solo si tiene el dato
      //   Cell: ({ cell }) => <Box sx={{  textAlign: "center" }}><div>Año {cell.getValue()}</div></Box>,
      //   enableEditing: false, //disable editing on this column
      //   Footer: () => (
      //     <FormGroup> 
      //       <Col style={{paddingBottom: '4px' }}>
      //         <Input  type="text" style={{ textAlign: "center", fontWeight: 'bold'}} disabled 
      //           value="Total">
      //         </Input>  
      //       </Col>
            
      //     </FormGroup>
      //   ),
      // },
      // {
      //   header: '10%',
      //   accessorKey: 'cantidadInscriptos',
      //   //accessorFn: (row) => `${row.Ctacte.Pensumcarrera.Carrera.Siglas} ${row.Ctacte.Pensumcarrera.Aniopensum}`, //accessorFn used to join multiple data into a single cell
      //   //accessorFn: (row) => <div style={{ textAlign: "center" }}>{row.cantidadInscriptos}</div>, //Muestra solo si tiene el dato
      //   enableEditing: false, //disable editing on this column
      //   //Filter: SelectColumnFilter,
      //   //filter: 'equals', // by default, filter: 'text', but in our case we don't want to filter options like text, we want to find exact match of selected option.
      //   //disableFilters: true
      //   enableColumnActions: false,
      //   aggregationFn: 'sum',
      //   AggregatedCell: ({ cell }) => <Box sx={{  textAlign: "center", fontWeight: 'bold' }}>{cell.getValue()}</Box>,
      //   //customize normal cell render on normal non-aggregated rows
      //   Cell: ({ cell }) => <div style={{ textAlign: "center" }}>{cell.getValue()}</div>,
      //   Footer: () => (
      //     <FormGroup >
      //       <Col style={{paddingBottom: '4px' }}>
      //         <Input type="text" style={{ textAlign: "center", fontWeight: 'bold', }} sx={{ width: 220 }} disabled 
      //           value={totalInscriptos}>
      //         </Input>
      //       </Col>
      //     </FormGroup>
      //   ),
      // },

    ]

    return arrayColumnas}, [datos, totalInscriptos, validationErrors, datosTipomov, datosTipocomprobante]
  );
 
  //DELETE action
  const openDeleteConfirmModal = (row) => {
    const deleteByIndex = index => {
      console.log(index);
      const datosFiltrados = datos.filter((_, i) => i !== index)
      console.log(datosFiltrados);
      //setDatos(filtered);

      // prevUsers) =>
      //   prevUsers?.filter((user) => user.id !== userId),
      setDatos([...datosFiltrados]);

        // setDatos(oldValues => {
        //      let x = oldValues?.filter((_, i) => i !== index)
        //      console.log(x);
        //      return x;
        // })

      
      // setDatos(oldValues => {
      //   let x = oldValues.filter((_, i) => i !== index)
      //   console.log(x);
      //   return x;
      // })
      
      
      // setDatos(oldValues => {
      //   //let x = oldValues.filter(fila => fila.id !== id);
      //   let x = oldValues.filter((fila) => fila.index !== index);
      //   console.log(x);
      //   return x
      // })
      //console.log(datos)

      // setDatos(old =>
      //   old.map((row, index) => {
      //     if (index == rowIndex) {
      //       return {
      //         ...old[rowIndex],
      //         [columnId]: value,
      //       }
      //     }
      //     return row
      //   })
      // )
    }
    console.log(row.index);
    //if (window.confirm(`¿Estás seguro de que quieres eliminar las filas seleccionadas ? ${row.original.customer_name}`)) {
    if (window.confirm(`¿Estás seguro de que quieres eliminar las filas seleccionadas ?`)) {
      //deleteUser(row.original.invoice_number);
      
      deleteByIndex(row.index);
    }
  };

  

//CREATE action
const handleCreateUser = async ({ values, table }) => {
  // const newValidationErrors = validateUser(values);
  // if (Object.values(newValidationErrors).some((error) => error)) {
  //   setValidationErrors(newValidationErrors);
  //   return;
  // }
  // setValidationErrors({});
  //await createUser(values);
  
  const data = [
    {

      cantidad: '1',
      descripcion: 'John', // key "name" matches `accessorKey` in ColumnDef down below
      precio: 50000, // key "age" matches `accessorKey` in ColumnDef down below
    }
  ];
  
  setDatos([...datos, { id: idfila, id_stock: idstock, cantidad: 1, descripcion: 'New Item', precio: 50000, total: 100000, sucursal:'ASU'}])
  setIdfila(idfila+1);
  //setDatos([...datos, data])

  

  //console.log(datos);
  //console.log(table);
  
  table.setCreatingRow(null); //exit creating mode
};

  // useEffect(() => {
  //   if(datos) {
  //     console.log("useEffect");
  //     setDatos(datos);
  //     console.log(datos);
  //   }
  // }
  // , [datos]);
  

  

  const table = useMaterialReactTable({
    id: "tabla-ventas",
    columns,
    //enableTopToolbar: false,
    //enableBottomToolbar: false,
    data: datos,
    //data: fetchedUsers,
    enableDensityToggle: true,
    //createDisplayMode: 'modal',
    //createDisplayMode: 'row',
    editDisplayMode: 'table',
    //editingMode:"table",
    enableEditing: true,
    //createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    
    //enableRowSelection: true,
    getRowId: (row) => row.id,
    
    

    // muiTableBodyRowProps: ({ row }) => ({
    //   onClick: row.getToggleSelectedHandler(),
    //   sx: { cursor: 'pointer' },
    // }),
    //enableSelectAll: true,
    //positionActionsColumn: 'last',
    
    // displayColumnDefOptions: {
    //   'mrt-row-expand': {
    //     enableResizing: true,
    //   },
    // },

    muiEditRowDialogProps: {
      //optionally customize the dialog
      //fullScreen: true
      maxWidth: 'xl'
    },
    //enableColumnResizing: true,
    //enableGrouping: true,
    
    enableStickyHeader: false,
    enableStickyFooter: false,
    enablePagination: false,
    enableGlobalFilter: false,
    enableColumnFilterModes: false,
    enableColumnOrdering: false,
    enableGrouping: false,
    enableColumnPinning: false,
    enableColumnActions: false,
    enableSorting: false,
    enableBottomToolbar:false,
    positionCreatingRow: 'bottom', //default is 'top'

     initialState: {
      density: 'compact',//set default density to compact
    //   expanded: false, //expand all groups by default
    //   //showGlobalFilter: true, //show the global filter by default
    //   //grouping: ['carrera'], //an array of columns to group by by default (can be multiple)
      columnVisibility: { id: false, id_stock:false}
    //   //sorting: [{ id: 'carrera', desc: false }], //sort by state by default
     },
    
    //muiToolbarAlertBannerChipProps: { color: 'primary' },
   // muiTableContainerProps: { sx: { maxHeight: 640 } },
    
    // muiSkeletonProps: {
    //   animation: 'wave',
    // },
    // muiLinearProgressProps: {
    //   color: 'primary',
    // },
    // muiCircularProgressProps: {
    //   color: 'primary',
    // },
    //onEditingRowCancel: () => setValidationErrors({}),
    //onCreatingRowCancel: () => setValidationErrors({}),
    //onCreatingRowSave: handleCreateUser,

    enableRowActions: true,
    getRowId: (row) => row.id,
   // renderRowActionMenuItems: ({ row }) => [
    //   <MenuItem key="edit" onClick={() => console.info('Edit')}>
    //     Edit
    //   </MenuItem>,
    //  <MenuItem key="delete" onClick={() => openDeleteConfirmModal(row)}>
     //    Delete
      // </MenuItem>,
    // ],
     renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    localization: MRT_Localization_ES,   

    //optionally customize modal content
    // renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
    //   <>
    //     <DialogTitle>Buscar Articulo</DialogTitle>
    //     <DialogContent
    //       sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
    //         <Form >
              
    //           <BuscarArticuloPage setshowBuscarPersona = {setshowBuscarPersona} setidstock= {setidstock} setidpersona = {setidpersona} setnombreyapellido = {setnombreyapellido} />
              
    //         </Form>
    //       {/*
    //       {internalEditComponents} or render custom edit components here */}
    //     </DialogContent>
    //     <DialogActions>
    //       <MRT_EditActionButtons variant="text" table={table} row={row} />
    //       {/*or render your own buttons */}
    //       <Button color="primary" onClick={handleSaveMensaje}>
    //           Aceptar
    //       </Button>
    //     </DialogActions>

    //   </>
    // ),

    renderToolbarInternalActions: ({ table }) => (
      <>
        {/* add your own custom print button or something 
        <IconButton onClick={() => showPrintPreview(true)}>
          <PrintIcon />
        </IconButton>*/}
        {/* built-in buttons (must pass in table prop for them to work!) */}
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
    
        {/* <MRT_ToggleDensePaddingButton table={table} /> */}
        <Button
          sx={{scale: "0.8" }} 
          variant="text"
          color='error'
          onClick={() => {

            setshowBuscarArticulo(true)


            //table.setCreatingRow(true);

            //or you can pass in a row object to set default values with the `createRow` helper function
           //table.setCreatingRow(
           //  createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
            //</> }),
           //);
            }}>
            <AddIcon sx={{scale: "1.5" }} color="success"/>
          </Button>

          {/* <Button
            sx={{scale: "0.8" }}
            variant="text"
            color="error"
            onClick={() => {
              // Delete Selected ROW
              const selectedRows = table.getSelectedRowModel().rows; //or read entire rows
              console.log(selectedRows);
              if(selectedRows[0]){
                openDeleteConfirmModal(selectedRows[0])

              }else{
                window.alert("Seleccione una fila y luego proceda a eliminarla")
              }
            }}
          >
          <DeleteIcon color="error" />
        </Button> */}
      </>
    ),
  });

  let tipopersonaBuscador = 2;

  return (
    <Container fluid >
      <Form>
        <FormGroup row>
              <Col sm={1}>
                <Input type="text" name="idpersona" id="idpersona" placeholder="Id" disabled={true} onChange={(event)=>this.inputChangedHandler(event)} value={idpersona} required={true} />
              </Col>
              <Col sm={3}>
                <Input type="text" name="nombrepersona" id="nombrepersona" placeholder="Nombre y apellido" disabled={true} onChange={(event)=>this.inputChangedHandler(event)} value={nombreyapellido} required={true} />
              </Col>
              <Col sm={1}>
                <Button color="primary" onClick={() => setshowBuscarPersona(true)} disabled={disablebtnPersona}><Icon.Search /></Button>
              </Col>
              <Col sm={2}>
                <Input id="fecha" label="Fecha" type="date" defaultValue={toDay} sx={{ width: 220 }} inputlabelprops={{shrink: true}}  />
              </Col>
              <Col sm={2}>
                <Input id="nroComprobante" name="nroComprobante" sx={{ width: 200 }} placeholder="Comp. N°" type="text" onChange={e => setValueComp(e.target.value)} value={valueComp} />
              </Col>
          </FormGroup>
          
        </Form>

        <MaterialReactTable
            table={table} //only pass in table instead of all table options
            
          />

        <Modal autoFocus = {false} show={showBuscarPersona} onHide={handleCloseBuscarPersona} size="lg">
            <Modal.Header>
                <Modal.Title>Busqueda de persona</Modal.Title>
                </Modal.Header>
            <Modal.Body>
                <BuscarPersonaPage posicionarTipopersona = {tipopersonaBuscador} setcargarGrilla = {setcargarGrilla} setshowBuscarPersona = {setshowBuscarPersona} setidpersona = {setidpersona} setnombreyapellido = {setnombreyapellido} />
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" onClick={handleSaveMensaje}>
                  Aceptar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal autoFocus = {false} show={showBuscarArticulo} onHide={cerrarBuscarArticulo} size="lg">
            {/* <Modal.Header>
                <Modal.Title>Buscar Artículo</Modal.Title>
                </Modal.Header> */}
            <Modal.Body>
              <BuscarArticuloPage idfila = {idfila} setdatoAgregar = {setdatoAgregar} posicionarSucursal ={1} setshowBuscarArticulo = {setshowBuscarArticulo} setidstock= {setidstock} setidpersona = {setidpersona} setnombreyapellido = {setnombreyapellido} />
            </Modal.Body>
            <Modal.Footer>
                {/* <Button color="primary" onClick={cerrarBuscarArticulo}>
                  Aceptar
                </Button> */}
            </Modal.Footer>
        </Modal>
    </Container>
    )
};

export default Ventas;