let accessToken="";

let url = "http://localhost:5000";
//let url = "http://api.uds.localhost";
let wsurl = "ws://api.uds.localhost";
let urlReportes = "http://pr.uds.localhost/proyecto";


//let url = "https://api.uds.edu.py";
//let wsurl = "wss://api.uds.edu.py";
//let urlReportes = "https://web.uds.edu.py/proyecto";

export const setAccessToken = (token) => {
    accessToken = token;
}

export const getAccessToken = () => {
    return accessToken;
}

export const getURL = () => {
    return url;
}

export const getwsURL = () => {
    return wsurl;
}

export const getURLReportes = () => {
    return urlReportes;
}
