import React, { useEffect, useState, useMemo }  from 'react'
import {Button, Container, Form, Row, Col, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert} from 'reactstrap';
//import {useQuery} from 'react-apollo';
//import gql from 'graphql-tag';
import { gql, useLazyQuery, useQuery } from '@apollo/client';

import { useTable, useSortBy, useFilters } from 'react-table';

import { createRow, MaterialReactTable, useMaterialReactTable, MRT_ShowHideColumnsButton,
  MRT_EditActionButtons, MRT_ToggleFullScreenButton, MRT_ToggleDensePaddingButton} from 'material-react-table';

import Table from 'react-bootstrap/Table';
import { Filter, DefaultColumnFilter } from './filters';
//import TableContainer from './TableContainer_sinPag';
//Material-UI Imports

import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  ListItemIcon,
  MenuItem,
  Typography,
  TextField,
  Stack
} from '@mui/material';

 
function BuscarArticuloPage({idfila, setdatoAgregar, posicionarSucursal, setcargarGrilla, setshowBuscarArticulo, setidstock, setnombreyapellido}) {
  //const inputReference = useRef(null);
  //console.log(posicionarSucursal);
  const [selectedId, setselectedId] = useState(-1);
  const [datos, setDatos] = React.useState([]);
  const [idfiltro, setidfiltro] = React.useState();
  const [nombrefiltro, setnombrefiltro] = React.useState("");
  const [apellidofiltro, setapellidofiltro] = React.useState("");
  const [nrodocfiltro, setnrodocfiltro] = React.useState("");
  const [sucursalfiltro, setsucursalfiltro] = React.useState(posicionarSucursal);

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const [sucursaltext, setsucursaltext] = useState("Sucursal");
  const [datosTP, setDatosTP] = useState([]);
  
  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [datoSelecionado, setdatoSelecionado] = useState();

  function ObtenerSucursalDescripcion ()  {
    //console.log(posicionarSucursal);
    const Query = gql` query obtenerDatos ($idsucursal: Int!) {
      condicionalSucursal(idsucursal: $idsucursal) {
        pk_sucursal
        sucursalDescrip
      }
    }`;
    const variables = { idsucursal: posicionarSucursal};
    const { loading, error, data } = useQuery(Query, { variables, fetchPolicy: "network-only" },);

    useEffect(() => {
        if(data) {
          const datos2 = data.condicionalSucursal;
          //console.log(datos2);
          setsucursaltext(datos2[0].sucursalDescrip);
        }
    }, [data]);

    if (loading) return <Alert color="info">
       Cargando...
    </Alert>;
    
    if (error) {
      console.log(error.message)
      return (
        <Alert color="danger">
          {error.message}
        </Alert>
      )
    }
  } 
  if (typeof(posicionarSucursal) !== 'undefined' && posicionarSucursal != null && posicionarSucursal != "") {
    //console.log("ENTRO");
    ObtenerSucursalDescripcion();
  }

  const QueryPersonas = gql` query obtenerDatos($idfiltro: String, $nombrefiltro: String, $sucursalfiltro: Int) {
    filtrarArticulos (idfiltro: $idfiltro, nombrefiltro: $nombrefiltro, sucursalfiltro: $sucursalfiltro) {
      existencia
      inversor
      pk_stock
      sucursal {
        pk_sucursal
        sucursalDescrip
      }
      precioventags
      articulo {
        nombrearticulo
        modelo
        marca {
          descripcion
          pk_marca
        }
        
        tipoarticulo {
          Descripcion
          Pk_TipoArticulo
        }
        Pk_Articulo
      }
      
    }
  }`;

  const variables = { idfiltro: idfiltro, nombrefiltro: nombrefiltro, apellidofiltro: apellidofiltro, nrodocfiltro: nrodocfiltro, sucursalfiltro: sucursalfiltro };
  /* const [loadPersonas] = useLazyQuery(
    QueryPersonas,
    { variables, 
      fetchPolicy: "network-only", 
      onCompleted: data1 => { 
        //console.log(data1);
        setDatos(data1.allpersonas);
      }}
  ); */

  const { loading, error, data } = useQuery(QueryPersonas, {variables, fetchPolicy: "network-only", });
  //console.log(data);

  useEffect(() => {
    if(data) {
      setDatos(data.filtrarArticulos);
      if (data.filtrarArticulos.length >0) {
        //const datos1 = data.allpersonas;
        //console.log(data.filtrarPersonas);
        
        //console.log(data.filtrarPersonas[0].Nombre);

        //setidpersona(data.filtrarPersonas[0].pk_persona || '');
        //setnombreyapellido(data.filtrarPersonas[0].Nombre + ' ' + data.filtrarPersonas[0].Apellido);
        let primerafila = data.filtrarArticulos[0];
        //console.log(primerafila);
        setdatoSelecionado({ id: idfila, id_stock: primerafila.pk_stock, cantidad: 1, descripcion: primerafila.articulo.nombrearticulo, precio: primerafila.precioventags, total: primerafila.precioventags, sucursal:'ASU'});
        //setselectedId(0);
        //setnombre(datos1.)
      }
    }
  }, [data]);

  const columns = useMemo(() => {
    const arrayColumnas =  [
      
      {
        accessorKey: 'pk_stock',
        header: 'ID stock',
        muiTableBodyCellProps: {
          align: 'center',
        },
        //enableEditing: false,
        size: 75,
      },
      {
        accessorKey: 'articulo.nombrearticulo',
        header: 'Artículo',
        size: 300,
        Cell: ({ cell }) =>  <Box sx={{ fontSize: 12, }}>{cell.getValue()}</Box>,
      },
      {
        header: 'Precio',
        accessorKey: 'precioventags',
        size: 100,
        
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) =>  <Box sx={{ fontSize: 12, display: 'inline' }}>{new Intl.NumberFormat("es-PY").format(cell.getValue())}</Box>,
      },
      
      {
        header: 'Stock',
        accessorKey: 'existencia',
        muiTableBodyCellProps: {
          align: 'center',
        },
        size: 70,
      },
      
      {
        header: 'Tipo',
        accessorKey: 'articulo.tipoarticulo.Descripcion',
        size: 120,
        Cell: ({ cell }) =>  <Box sx={{ fontSize: 12, }}>{cell.getValue()}</Box>,
      },
      {
        header: 'Marca',
        accessorKey: 'articulo.marca.descripcion',
        size: 80,
        Cell: ({ cell }) =>  <Box sx={{ fontSize: 12, }}>{cell.getValue()}</Box>,
      },
      {
        header: 'Inversor',
        accessorKey: 'inversor',
        muiTableBodyCellProps: {
          align: 'center',
        },
        size: 60,
      },
      {
        header: 'SUC',
        accessorKey: 'sucursal.sucursalDescrip',
        muiTableBodyCellProps: {
          align: 'center',
        },
        size: 60,
      },
    ]

    return arrayColumnas}, [datos]
  );

  

  //const data = React.useMemo(() => makeData(100000), [])
  function cerrar()  {
    //loadPersonas();
    setdatoAgregar(datoSelecionado);
    setshowBuscarArticulo(false);
  }
  
  function handleChangeId(e) {
    //console.log(e.target.value);
    setidfiltro(e.target.value)
    //loadPersonas();
  }

  function handleChangeNombre(e) {
    //console.log(e.target.value);
    setnombrefiltro(e.target.value)
    //loadPersonas();
  }

  function handleChangeApellido(e) {
    //console.log(e.target.value);
    setapellidofiltro(e.target.value)
    //loadPersonas();
  }

  function handleChangeNroDoc(e) {
    //console.log(e.target.value);
    setnrodocfiltro(e.target.value)
    //loadPersonas();
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      setshowBuscarArticulo(false);
      setcargarGrilla(true);
    }
  };

  function ObtenerDatosTipopersona ()  {
    const Query = gql` query obtenerDatos {
      listaSucursales {
        pk_sucursal
        sucursalDescrip
      }
    }`;
    
    const { loading, error, data } = useQuery(Query, {fetchPolicy: "network-only" },);

    useEffect(() => {
        if(data) {
          const datos1 = data.listaSucursales;
          setDatosTP(datos1);
        }
    }, [data]);

    if (loading) return <Alert color="info">
       Cargando...
    </Alert>;
    
    if (error) {
      console.log(error.message)
      return (
        <Alert color="danger">
          {error.message}
        </Alert>
      )
    }
  } 

  ObtenerDatosTipopersona();

  const MostrarComboSucursal = () => {
    
    //console.log(posicionarSucursal);
    const handleClick = (e) => {
        //console.log(e.target.value);
        var wvalor =  parseInt(e.target.value);
        if (wvalor) {
          setsucursaltext(e.target.innerText);
          setsucursalfiltro(wvalor)
          //loadAsignaturas();
        }
    } 
    return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret color="primary">
                {sucursaltext}
              </DropdownToggle>          
              <DropdownMenu>
                  {datosTP.map(item => (
                      <DropdownItem
                          key={item.pk_sucursal}
                          value={item.pk_sucursal}
                          onClick={handleClick}>
                        {item.sucursalDescrip}
                      </DropdownItem>
                  ))}
              </DropdownMenu>
        </ButtonDropdown>
    )
  }
 
  const table = useMaterialReactTable({
    //id: "tabla-articulos",
    columns: columns,
    data: datos,
    idfila: idfila,
 
     //enableFilters:true,
    enableColumnFilterModes: true,
    // enableSorting: false,
    // enablePagination: false,
    enableGlobalFilter: false,
    // //enableDensityToggle: false,
    // enableFullScreenToggle: false,
    // enableColumnResizing: true,
    // enableStickyHeader: false,
    // enableStickyFooter: false,
    // enableColumnPinning: false,
    // enableColumnActions:false,
    // enableBottomToolbar:false,
    //enableRowSelection: true,
    enableMultiRowSelection: false,
    getRowId: (row) => row.pk_stock,
    //enableHiding:false,
    initialState: {
      density: 'compact',//set default density to compact
      //showColumnFilters: true,

      pagination: { pageSize: 5, pageIndex: 0 }
    //   expanded: false, //expand all groups by default
    //   //showGlobalFilter: true, //show the global filter by default
    //   //grouping: ['carrera'], //an array of columns to group by by default (can be multiple)
      //columnVisibility: { id: false, id_stock:false}
    //   //sorting: [{ id: 'carrera', desc: false }], //sort by state by default
    },
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [5, 10],
      shape: 'rounded',
      variant: 'outlined',
    },
    muiTableBodyRowProps: ({ row }) => ({
      // onClick: row.getToggleSelectedHandler(),
      // sx: { cursor: 'pointer' },
       onClick: (event) => {
      //   row.getToggleSelectedHandler()
      //   //console.info(row)
      //   //setidstock(row.original.pk_stock)
      //   //console.log('it produced this event:', row.id) 
      //   //setselectedId(row.id)
        setdatoSelecionado({ id: idfila, id_stock: row.original.pk_stock, cantidad: 1, descripcion: row.original.articulo.nombrearticulo, precio: row.original.precioventags, total: row.original.precioventags, sucursal:'ASU'});
      //   //setnombreyapellido(row.values.Nombre + ' ' + row.values.Apellido);
       },

      onDoubleClick: (event) => {
        //setidstock(row.original.pk_stock)
        //setdatoAgregar
        cerrar();
        //setdatoAgregar({ id: idfila, id_stock: row.original.pk_stock, cantidad: 1, descripcion: row.original.articulo.nombrearticulo, precio: row.original.precioventags, total: row.original.precioventags, sucursal:'ASU'});
        //cerrarBuscarArticulo();
        //setshowBuscarArticulo(false);
        //console.info("DOBLE CLICK " + event, row.id);
      },
      // sx: {
      //   cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      // },
    }),
    positionToolbarAlertBanner: 'bottom', //move the alert banner to the bottom
    onRowSelectionChange: setRowSelection, //connect internal row selection state to your own
    state: { rowSelection }, //pass our managed row selection state to the table to use
  });

  return (
    <Container style={{ padding: "0px" }}>
    
      <Form style={{ marginBottom: "15px" }}>
        <Row>
            <Col>
              <Input type="text"  name="idpersonaFiltro" id="idpersonaFiltro" onChange={handleChangeId} onKeyDown={handleKeyDown} placeholder="ID"  />
            </Col>
            <Col >
              <Input type="text" autoFocus={true} name="nombre" id="nombre" onChange={handleChangeNombre} onKeyDown={handleKeyDown} placeholder="Artículo"/>
            </Col>
            <Col >
              <Input type="text" name="apellido" id="apellido" onChange={handleChangeApellido} onKeyDown={handleKeyDown} placeholder="Apellido"/>
            </Col>
            <Col >
              <Input type="text" name="nrodoc" id="nrodoc" onChange={handleChangeNroDoc} onKeyDown={handleKeyDown} placeholder="Nro. doc."/>
            </Col>
            
            <Col >
              {MostrarComboSucursal ()}
            </Col>
        </Row>
      </Form>
      
      <MaterialReactTable
            table={table} //only pass in table instead of all table options
            idfila={idfila}
          
          />
      <Button color="primary" onClick={cerrar}>
          Aceptar
      </Button>
      
    </Container>
  )
}

export default BuscarArticuloPage;