export type User = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    state: string;
  };
  
  export const fakeData: User[] = [
    {
      id: '9s41rp',
      firstName: 'Kelvin',
      lastName: 'Langosh',
      email: 'Jerod14@hotmail.com',
      state: 'Ohio',
    },
    {
      id: '08m6rx',
      firstName: 'Molly',
      lastName: 'Purdy',
      email: 'Hugh.Dach79@hotmail.com',
      state: 'Rhode Island',
    },
    {
      id: '5ymtrc',
      firstName: 'Henry',
      lastName: 'Lynch',
      email: 'Camden.Macejkovic@yahoo.com',
      state: 'California',
    },
    {
      id: 'ek5b97',
      firstName: 'Glenda',
      lastName: 'Douglas',
      email: 'Eric0@yahoo.com',
      state: 'Montana',
    },
    
  ];
  
  //50 us states array
  export const usStates = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    
  ];
  