

import React , { useEffect, useState } from 'react';
//import {BrowserRouter, Route,Switch, Link, NavLink } from 'react-router-dom';
//Importar paginas
 //import LoginPage from './pages/login';
// import TicketPage from './pages/tickets';
// import RecFacial from './pages/recfacial';
// import UserList from './pages/userlist';
// import NuevoUser from './pages/nuevouser';
// import CrearUser from './pages/crear_user';
// //Importar componentes
import Layout from './componets/Layout';
//

import Navigarionbar from './componets/BrowserrouteNotas';
//import Navigarionbar from './componets/Browserroute';



import 'bootstrap/dist/css/bootstrap.min.css';
import { setAccessToken, getURL } from './AccessToken';
//import { Alert } from 'reactstrap';
import Alert from 'react-bootstrap/Alert';

function App() {
  //Obtenermos un nuevo accesToken enviando el refresh token cuando el usuario refresca la página
  const [loading, setLoading] = useState(true )

  useEffect(() => {
    const url = getURL()+'/refresh_token';
    
    fetch(url, {
      method: 'POST',
      credentials: 'include', // Don't forget to specify this if you need cookies

    })
    .then(async respuesta => {
        const accessToken = await respuesta.json();
        //const accessToken = await respuesta;
        //console.log("NUEVO ACCES TOKEN DEVUELTO " + accessToken.accessToken);
        //Guardamos el token en una variable
        setAccessToken(accessToken.accessToken)
        //console.log("NUEVO ACCES TOKEN DEVUELTO " + JSON.stringify(accessToken));
        setLoading(false);
    })
  }, []);

  if (loading){
    return (
      <Layout>
        <Alert color="info">
          Cargando
        </Alert> 
      </Layout>
    );
  }
  return (
    <React.Fragment >

        {/* comentarios  <LoginPage />*/}
        
          <Navigarionbar />
        
      </React.Fragment>
  );
}

export default App;
