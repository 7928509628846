import React, { useEffect, useState, useContext } from 'react';
//import gql from 'graphql-tag';
import {Container, CardTitle, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Alert, Form,Card, Input, Row,  Col } from 'reactstrap';
//import {useQuery, useLazyQuery} from 'react-apollo';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import UserContext from '../componets/userContext';
import * as Icon from 'react-bootstrap-icons';
import "../css/styles.css";
//import { useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import BuscarPersonaPage from './Page_buscarPersona';
import {getURLReportes} from '../AccessToken';
import TableContainer from './TableContainer_sinPag';
import { SelectColumnFilter } from './filters';

function HomologacionesPage(props) {
    const usuario = useContext(UserContext)  
    let persona = '';
    let tipopersona = usuario.persona.personatipopers[0].TipoPersona.Pk_tipopersona;
    let nomApel = '';
    let BotonBuscarPersonaDisable = false;
    //if (tipopersona==1) {
      persona = usuario.persona.pk_persona;
    //  nomApel = usuario.persona.Nombre + ' ' + usuario.persona.Apellido;
      //BotonBuscarPersonaDisable = true;
    //}
    //console.log("USER EN CONTEXT " + JSON.stringify(usuario));
    const [disablebtnPersona, setDisablebtnPersona] = React.useState(BotonBuscarPersonaDisable);
    const [idpersona, setidpersona] = useState('');
    const [nombreyapellido, setnombreyapellido] = useState("");
    
    const [showBuscarPersona, setshowBuscarPersona] = useState(false);

    const [datosPensum, setDatosPensum] = useState([]);
    const [pensumtext, setpensumtext] = useState("Pensum");
    const [pensum, setpensum] = useState();
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    const [datosAniolec, setdatosAniolec] = useState([]);
    const [carreratext, setcarreratext] = useState("Carrera");
    const [carrera, setcarrera] = useState();
    const [dropdownOpenAL, setdropdownOpenAL] = useState(false);
    const toggleAL = () => setdropdownOpenAL(!dropdownOpenAL);

    const [cargarGrilla, setcargarGrilla] = useState(false);

    //const [reporte, setreporte] = useState("");
    const [MensajeError, setMensajeError] = useState("");

    const togglereporte = () => setdropdownOpenreporte(!dropdownOpenreporte);
    const [dropdownOpenreporte, setdropdownOpenreporte] = useState(false);
    const [reportetext, setreportetext] = useState("Reporte");
    
    const [datos, setDatos] = React.useState([]);

    const reporte = "homologaciones";
    let tipopersonaBuscador = 2;
    const BotonImprimir = () => {
      const Validar = (e) => {
        let isValid = true;
        if (nombreyapellido == "") {
          setMensajeError("Debe seleccionar la Persona");
          isValid = false;
          return isValid;
        } 
        if (pensumtext == "Pensum") {
          setMensajeError("Debe seleccionar el Pensum");
          isValid = false;
          return isValid;
        } 
        if (carreratext == "Carrera") {
          setMensajeError("Debe seleccionar la Carrera");
          isValid = false;
          return isValid;
        } 
        
        return isValid;
      }
      //const [value, setValue] = React.useState(initialValue);
      const handleSubmit = (e) => {
        //Validar
        e.preventDefault();
        let isValid = Validar();
        if (!isValid) {
          return;
        }
        else {      
          const win = window.open(getURLReportes() + `/reportes/reportes.jsp?reporte=${reporte}&Pensumcarrera=${carrera}&persona=${idpersona}`, "_blank");
          win.focus();
        }
      }
      //console.log(original);
      //const reporte1 = 'reporte loco';
      //let reporte1 = original.reporte;
      //console.log(original.reporte);
      return (
        <div className="my-boton-imprimir"> 
          <Link to= "#" onClick={handleSubmit}
            target="new" style={{ alin: '#FFF' }}>
            <Icon.PrinterFill size={40} />
          </Link>
        </div>
      )       
    }
    
    const QueryAL = gql` query AllpensuncarreraUDS($aniopensum: Int) {
      allpensuncarreraUDS(Aniopensum: $aniopensum) {
        Pk_PensumCarrera
        Carrera {
          Pk_Carrera
          Descripcion
          Siglas
        }
      }
    }`;

    const [ObtenerDatoscarrera, { dataAL }]  = useLazyQuery(QueryAL, {
      variables: { 
        aniopensum: pensum}, fetchPolicy: "network-only",
        onCompleted: dataAL => {
          setdatosAniolec(dataAL.allpensuncarreraUDS);
       },
    });

    const MostrarCombocarrera = () => {
      const handleClick = (e) => {
        setMensajeError("");
        setcarreratext(e.target.innerText);
        var wcarrera = parseInt(e.target.value);
        //console.log(e.target.value);
        if (wcarrera) {
          setcarrera(wcarrera);
          //MostrarDatosCiclo();
        }  
      } 
      return (
          <ButtonDropdown isOpen={dropdownOpenAL} toggle={toggleAL} className="my-dropdown">
            <DropdownToggle caret >
              {carreratext}
            </DropdownToggle>          
            <DropdownMenu>
                {datosAniolec.map(item => (
                    <DropdownItem
                        key={item.Pk_PensumCarrera}
                        value={item.Pk_PensumCarrera}
                        onClick={handleClick}>
                            
                      {item.Carrera.Descripcion}
                    </DropdownItem>
                ))}
            </DropdownMenu>
          </ButtonDropdown>
      )
    }

    function ObtenerDatosPensum ()  {
        const Query = gql` query ObtenerAnioPensum {
          obtenerAnioPensum {
            Aniopensum
            Pk_PensumCarrera
          }
        }`;
        
        const { loading, error, data } = useQuery(Query, {fetchPolicy: "network-only" });

        useEffect(() => {
            if(data) {
              const datos1 = data.obtenerAnioPensum;
              //console.log(datos1);
              setDatosPensum(datos1);
            }
        }, [data]);
    
        if (loading) return 'Loading...';
        
        if (error) {
          console.log(error.message)
          return (
            <Alert color="danger">
              {error.message}
            </Alert>
          )
        }
    } 

    ObtenerDatosPensum();
    
    const MostrarComboPensum = () => {
        const handleClick = (e) => {
          setMensajeError("");

          setcarreratext("Carrera");
          setcarrera(0);
        
          setpensumtext(e.target.innerText);
           //console.log(e.target.value)
          var wPensum =  parseInt(e.target.value);
          if (wPensum) {
            setpensum(wPensum);
            ObtenerDatoscarrera();
          }  
        } 
        return (
            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="my-dropdown" >
              <DropdownToggle caret >
                {pensumtext}
              </DropdownToggle>          
              <DropdownMenu >
                  {
                  datosPensum.map(item => (
                      <DropdownItem 
                          key={item.Aniopensum}
                          value={item.Aniopensum}
                          onClick={handleClick}>
                        {item.Aniopensum}
                      </DropdownItem>
                  ))                  
                  }
              </DropdownMenu>
            </ButtonDropdown>
        )
    }

    const columns = React.useMemo(
      () => [
        {
          Header: 'Carrera',
          accessor: 'carreraDescrip', // accessor is the "key" in the data
          disableFilters: true
        },
        {
          Header: 'Pensum',
          accessor: 'Aniopensum', // accessor is the "key" in the data
          disableFilters: true
        },
        {
          Header: 'Última Inscripción',
          accessor: 'maximoAniolectivo', // accessor is the "key" in the data
          disableFilters: true
        },
        {
          Header: 'Año',
          accessor: 'maximoAnioCursado', // accessor is the "key" in the data
          disableFilters: true
        },
        {
          Header: 'Deuda',
          accessor: 'montoTotalAdeudadoEnCarrera', // accessor is the "key" in the data
          disableFilters: true,
          Cell: props => new Intl.NumberFormat("es-ES").format(props.value)
        },
      ],
      []
  );

    const QueryCursado = gql` query PensumcarreraUDSPorAlumno($persona: Int) {
      pensumcarreraUDSPorAlumno(persona: $persona) {
        Aniopensum
        carreraDescrip
        maxanio
        maximoAnioCursado
        maximoAniolectivo
        montoTotalAdeudadoEnCarrera
      }
    }`;

    const [loadCursado, { error, data }] = useLazyQuery(
      QueryCursado,
      { variables: { 
        persona: idpersona,
       }, 
        fetchPolicy: "network-only",
        onCompleted: data => {
         console.log('data ', data);
          const datos = data.pensumcarreraUDSPorAlumno;
          //console.log("TIK" + JSON.stringify(tableData));
          setDatos(datos);
        }
          
      }
    ); 

     useEffect(() => {
       if(cargarGrilla) {
         loadCursado();
       }
   }, [cargarGrilla]);

    async function cerrarBuscarPersona() {
      setMensajeError("");
      //loadCursado();
      setshowBuscarPersona(false);
      setcargarGrilla(true);
    }

    return (
      <Container>
          <Col className="col-md-8 mx-auto">
              <Card className="text-center">
                <CardTitle  tag="h4" className="card-header"> 
                  Homologaciones 
                </CardTitle>
                <Container className="mt-2">
                    <Form>
                      <FormGroup>
                        <FormGroup row>
                          <Col sm={2}>
                            <Input type="text" name="idpersona" id="idpersona" placeholder="Id" disabled={true} onChange={(event)=>this.inputChangedHandler(event)} value={idpersona} required={true} />
                          </Col>
                          <Col sm={8}>
                            <Input type="text" name="nombrepersona" id="nombrepersona" placeholder="Nombre y apellido" disabled={true} onChange={(event)=>this.inputChangedHandler(event)} value={nombreyapellido} required={true} />
                          </Col>
                          <Col sm={2}>
                            <Button color="primary" onClick={() => setshowBuscarPersona(true)} disabled={disablebtnPersona}><Icon.Search /></Button>
                          </Col>
                        </FormGroup>

                        
                        <FormGroup row>
                          <Col sm={2}>{MostrarComboPensum()} </Col>
                          <Col>{MostrarCombocarrera()} </Col>
                        </FormGroup>


                        <Row>{MensajeError ? <Alert color="danger" className="my-dropdown">{MensajeError}</Alert>:""}</Row>
                        <Row >{BotonImprimir()}</Row>
                      </FormGroup>

              
                      <CardTitle  tag="h4" className="card-header"> 
                  Estado 
                </CardTitle>
                
                   
                      <FormGroup>
                      <TableContainer 
                        columns={columns} 
                        data={datos}
                      />
                      </FormGroup>
                  
                 
                      

                    </Form>
                </Container>
                
              </Card>


                

          </Col>

          <Modal show={showBuscarPersona} onHide={cerrarBuscarPersona} size="lg">
              <Modal.Header>
                <Modal.Title>Busqueda de persona</Modal.Title>
                </Modal.Header>
              <Modal.Body>
                  <BuscarPersonaPage posicionarTipopersona = {tipopersonaBuscador} setcargarGrilla = {setcargarGrilla} setshowBuscarPersona = {setshowBuscarPersona} setidpersona = {setidpersona} setnombreyapellido = {setnombreyapellido}/>
              </Modal.Body>
                <Modal.Footer>
                  
                  <Button color="primary" onClick={cerrarBuscarPersona}>
                    Aceptar
                  </Button>
              </Modal.Footer>
          </Modal>
      </Container>
    )
}
export default HomologacionesPage;