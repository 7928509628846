import React, { useState } from "react"
import {Container} from 'reactstrap';
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, FormGroup } from 'reactstrap';
//import {useQuery} from 'react-apollo';
//import gql from 'graphql-tag';
import TableContainer from './TableContainer_sinPag';
import {useHistory} from "react-router-dom";
//import { useNavigate } from 'react-router-dom';

import {Button, Input, Row, Col, Alert } from 'reactstrap';
//import { getURL} from '../AccessToken';
//import {useLazyQuery, useMutation} from 'react-apollo';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
//import { useApolloClient } from '@apollo/react-hooks';
import Modal from 'react-bootstrap/Modal';
import { DefaultColumnFilter } from './filters';


//import UserContext from '../componets/userContext';
//import moment from "moment";
//var async = require("async");
function Notas_page(props)  {
  //const client = useApolloClient();
  //const serverurl = getURL()+'/graphql';
  const {aulacompartida} = props.location.state;
  const {institucion} = props.location.state;
  const {aniolectivo} = props.location.state;
  const {detalleperiodo} = props.location.state;
  const {horario} = props.location.state;
  const {seccion} = props.location.state;
  const {asignatura} = props.location.state;
  const {curso} = props.location.state;
  const {persona} = props.location.state;
  const {asigdescrip} =  props.location.state;
  const {EsTesis} =  props.location.state;

  //console.log("Persona: " + persona);
  var waulacompartida= "null";
  if (aulacompartida) {
     waulacompartida = aulacompartida.toString();
  }
  
  const winstitucion = parseInt(institucion);
  const waniolectivo = parseInt(aniolectivo);
  const wdetalleperiodo = parseInt(detalleperiodo);
  const whorario = parseInt(horario);
  const wseccion = parseInt(seccion);
  const wasignatura = parseInt(asignatura);
  const wcurso = parseInt(curso);
  const wpersona = parseInt(persona);
  //const wEsTesis = Boolean(Number(EsTesis));
  
  //const usuario = useContext(UserContext) 

  const [wdetallesistemaevaluacion, setwdetallesistemaevaluacion] = useState(0);
  const [Notatotal, setNotatotal] = React.useState(null);
  //const [Ponderacion, setPonderacion] = React.useState(null);
  const [CabeceraNotas, setCabeceraNotas] = React.useState(null);
  const [ClaseTipoexamen, setClaseTipoexamen] = React.useState(null);
  const [pkTipoexamen, setPkTipoexamen] = React.useState(null);
  const [Refrescar, setRefrescar] = React.useState(false);
  const [AlumnosMA , setAlumnosMA] = React.useState([]);

  var AlumnosMateriasAprobadas = [];
  React.useEffect(() => {
    if (Refrescar) {
      setRefrescar(false);    
    }
  }, [Refrescar]) 


 /*  let currentTimestamp = Date.now()
  console.log(currentTimestamp); // get current timestamp
  let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(currentTimestamp)
  console.log(date); */
  
  //console.log(waulacompartida); 
  // console.log(institucion); 
  // console.log(aniolectivo); 
  // console.log(detalleperiodo); 
  // console.log(seccion); 
  // console.log(wasignatura); 
  
  //const wpersona = 15
  const history = useHistory();
  //const navigate = useNavigate();

  //const [disableEdit, setDisableEdit] = useState(true);
  
  const [MensajeNota, setMensajeNota] = useState();
  const [MensajeError, setMensajeError] = useState();

  const [deshabilitarCancelar, setDeshabilitarCancelar] = useState(true);

  const [tipoExamentext, settipoExamentext] = useState("Tipo de examen");
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const [fechaExamen, setfechaExamen] = useState();
  const [diasParaCargaWeb, setdiasParaCargaWeb] = useState();

  //OBTENER LA FECHA ACTUAL 
  const [fechaActual, setfechaActual] = useState();
  const ObtenerFecha = gql` query {
    obtenerFecha
  }`;
  const { dataFec } = useQuery(ObtenerFecha,
     { 
       fetchPolicy: "network-only",
       onCompleted: dataFec => {
         //console.log('data ', dataFec);
         setfechaActual(dataFec.obtenerFecha);
         //console.log('data ', dataFec.obtenerFecha);
      },
     }
   ); 
  
  //console.log('fecha actual ', fechaActual);

  const QueryAgregarNota = gql`
    mutation AgregarNota($notas: ID!, $detallesistemaevaluacion: Int!, $examen: Int!, $notaNueva: Float, 
      $temaTesis: String, $CabeceraNotas: String, $AnioLectivo : Int, $PensumCarrera: Int, $DetalleAlumnosInscriptos: Int,
      $pk_NotasDetalles: Int, $notaOriginal: Float) {
        AgregarNotasDetalles(
            Notas: $notas 
            iddetallesistemaevaluacion: $detallesistemaevaluacion
            idexamen: $examen
            Nota: $notaNueva
            NotaDocente: $notaNueva
            temaTesis: $temaTesis
            CabeceraNotas: $CabeceraNotas
            AnioLectivo: $AnioLectivo
            PensumCarrera: $PensumCarrera
            DetalleAlumnosInscriptos: $DetalleAlumnosInscriptos
            pk_NotasDetalles:$pk_NotasDetalles
            notaOriginal: $notaOriginal
            ) 
        {
          pk_Notas
          temaTesis
       }
    }`;

/*   const QueryActualizarNota = gql`
    mutation ActualizarNota($pk_NotasDetalles: ID!, $notaNueva: Float!) {
      ActualizarNotasDetalles(
        idnotasdetalles: $pk_NotasDetalles 
        Nota: $notaNueva
        NotaDocente: $notaNueva) 
      {
        pk_NotasDetalles
      }
    }`;
   */
  const [agregarNota, { errorAgrNota }] = useMutation(QueryAgregarNota);

  const QueryExamenExclu = gql`mutation obtenerExcluyentes($notas: ID!, $detallesistemaevaluacion: Int!) {
    obtenerExamenExcluyenteRendido(Notas: $notas iddetallesistemaevaluacion: $detallesistemaevaluacion) {
      Descripcion
    }
  }`;
  const [obtenerExamenExcluyenteRendido, { errorEA}] = useMutation(QueryExamenExclu);
  
  const QueryDerechoAexamen = gql`mutation obtenerDerechoAexaemn($Arancel: Int!, $Curso: Int!, $Asignatura: Int!, $DetalleSistemaEvaluacion: Int!, $Persona: Int!, $Examen: Int!) {
    obtenerDerechoAexamenProcesado(Arancel: $Arancel Curso: $Curso Asignatura: $Asignatura DetalleSistemaEvaluacion: $DetalleSistemaEvaluacion Persona: $Persona Examen: $Examen) {
      MontoDebe
      MontoHaber
    }
  }`;
  const [obtenerDerechoAexamenProcesado, { errorDAP}] = useMutation(QueryDerechoAexamen);

  const QueryDerechoAexamenE = gql`mutation obtenerDerechoAexaemn($Arancel: Int!, $Curso: Int!, $Asignatura: Int!, $DetalleSistemaEvaluacion: Int!, $Persona: Int!, $Examen: Int!) {
    obtenerExistenciaDerechoAexamen(Arancel: $Arancel Curso: $Curso Asignatura: $Asignatura DetalleSistemaEvaluacion: $DetalleSistemaEvaluacion Persona: $Persona Examen: $Examen) {
      Descripcion
    }
  }`;
  const [obtenerExistenciaDerechoAexamen, { errorEDA}] = useMutation(QueryDerechoAexamenE);

  const QueryExaExcluyentes = gql`mutation obtenerDerechoAexaemnExc($Arancel: Int!, $Curso: Int!, $Asignatura: Int!, $TipoExamen: Int!, $Persona: Int!) {
    obtenerExamenesExcluyentes(Arancel: $Arancel Curso: $Curso Asignatura: $Asignatura TipoExamen: $TipoExamen Persona: $Persona) 
  }`;
  const [obtenerExamenesExcluyentes, { errorEE}] = useMutation(QueryExaExcluyentes);

  const QueryAsig = gql`query obtenerdatos($institucion: Int!, $persona: Int!, $aniolectivo: Int!, $asignatura: Int!, $detalleperiodo: Int!, $horario: Int!, $seccion: Int!, $aulacompartida: String, $detallesistemaevaluacion: Int!) {
    notas_Alumnos(institucion: $institucion persona: $persona aniolectivo: $aniolectivo asignatura:$asignatura, detalleperiodo: $detalleperiodo, horario: $horario, seccion: $seccion, aulacompartida: $aulacompartida, detallesistemaevaluacion: $detallesistemaevaluacion) {
        pk_Notas
        Asignatura
        temaTesis
        Estado
        Curso{
          Pk_Curso
          AnioLectivo
          PensumCarrera
        }
        detallealumnosinscriptos{
          Pk_detalleAlumnosInscriptos
          Examen{
            Pk_Examen
          }
          InstitucionPersona {
            Persona{
              pk_persona
              Nrodocumento
              Nombre
              Apellido
            }
          }
        }
        getnotasdetalles(detallesistemaevaluacion:$detallesistemaevaluacion) {
          pk_NotasDetalles
          Nota
          NotaDocente
          Fecha
        }
    }
  }`;

  const [loadAlumnos, { error, data }] = useLazyQuery(
    QueryAsig,
    { variables: { 
      institucion: winstitucion,
      persona: wpersona,
      aniolectivo: waniolectivo,
      asignatura: wasignatura,
      detalleperiodo: wdetalleperiodo,
      horario:whorario,
      seccion:wseccion,
      aulacompartida:waulacompartida,
      detallesistemaevaluacion:wdetallesistemaevaluacion,
     }, 
      fetchPolicy: "network-only",
      onCompleted: data => {
       //console.log('data ', data);
        const datos1 = data.notas_Alumnos;
        //console.log(datos1);
        //var NotafinalCargada = false;

        let tableData = []
        //datos1.forEach((item) => {
        for (const item of datos1) {
          let object = {
            //select and modify data from incoming server data
            pk_Notas : item.pk_Notas,
            Asignatura: item.Asignatura,
            temaTesis : item.temaTesis,
            Estado : item.Estado,
            //Estos coloco como estaban por que no se van a modificar en la grilla
            Curso: item.Curso,
            detallealumnosinscriptos: item.detallealumnosinscriptos,
            //estos si cambio a la primera linea por que si se van a modificar en la grilla
            pk_NotasDetalles: item.getnotasdetalles[0].pk_NotasDetalles,
            Nota: item.getnotasdetalles[0].Nota,
            NotaOriginal: item.getnotasdetalles[0].Nota,
            NotaDocente: item.getnotasdetalles[0].NotaDocente,
            Fecha : item.getnotasdetalles[0].Fecha,
            //NotaDocenteOriginal: item.getnotasdetalles[0].NotaDocente
          }

          /* if (typeof object.Nota === 'number') {
            NotafinalCargada = true;
          } */
          //if (object.Nota) {
          //  NotafinalCargada = true;
          //}

          //console.log(object.temaTesis);
          tableData.push(object)
        }
        //)
        setMensajeNota();
        //console.log(tableData);
        //Habilitamos el boton de edición
        /* if (datos1.length>0){
          if (NotafinalCargada) {
            //setMensajeNota("Si desea modificar una nota ya publicada, favor comunicarse con secretaría.")
            setMensajeNota()
            //setDisableEdit(true)
          }
          else{
            setMensajeNota()
            //setDisableEdit(false)
          }
        } 
        else{
          setMensajeNota()
          //setDisableEdit(true)
        } */


        //console.log("TIK" + JSON.stringify(tableData));
        setDatos(tableData);
        //console.log("datos" + JSON.stringify(tableData));
        setOriginalData(tableData);
      }
     },
  ); 

  const QueryTipoExamen = gql`
      query obtenerDatosDetalleExamen($curso: Int!, $asignatura: Int!, $detallesistemaevaluacion: Int!) {
        obtenerUnDetalleexamen (curso: $curso, asignatura: $asignatura, detallesistemaevaluacion: $detallesistemaevaluacion) {
          Fecha
          NotaTotExa
          Ponderacion
          diasParaCargaWeb
          DetalleSistemaEvaluacion {
            pk_DetalleSistemaEvaluacion
            TipoExamen {
              Pk_TipoExamen
              CabeceraNotas
              clasetipoexamen
            }
          }
        }
      }`;

  const [MostrarDatosExamen, { error: errorDE, data: dataDE }]  = useLazyQuery(QueryTipoExamen, {
    variables: { 
      curso: wcurso, 
      asignatura: wasignatura, 
      detallesistemaevaluacion: wdetallesistemaevaluacion }, fetchPolicy: "network-only"
  });
  
  const [datosDE, setdatosDE] = useState([]);
  
  function formatDate (input) {
    var datePart = input.match(/\d+/g),
    year = datePart[0], // .substring(2) get only two digits
    month = datePart[1], 
    day = datePart[2];
    //return new Date(year, month, day)
    
    return year+'/'+month+'/'+day;
  }

  const MostrarFechaYnotaMaxima2 = () => {
      //const datosDE = data.obtenerUnDetalleexamen[0];
      React.useEffect(() => {
        if (dataDE) {
          //console.log(dataDE);
          if (dataDE.obtenerUnDetalleexamen.length >0) {
            //console.log("tiene algo");
            setNotatotal(dataDE.obtenerUnDetalleexamen[0].NotaTotExa);
            //setPonderacion(dataDE.obtenerUnDetalleexamen[0].Ponderacion);
            setCabeceraNotas(dataDE.obtenerUnDetalleexamen[0].DetalleSistemaEvaluacion.TipoExamen.CabeceraNotas);
            setClaseTipoexamen(dataDE.obtenerUnDetalleexamen[0].DetalleSistemaEvaluacion.TipoExamen.clasetipoexamen);
            setPkTipoexamen(parseInt(dataDE.obtenerUnDetalleexamen[0].DetalleSistemaEvaluacion.TipoExamen.Pk_TipoExamen));
            //let fechaexamen = new Intl.DateTimeFormat('es-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(dataDE.obtenerUnDetalleexamen[0].Fecha)
            
            setdiasParaCargaWeb(parseInt(dataDE.obtenerUnDetalleexamen[0].diasParaCargaWeb));
            let fechaExamen1 = formatDate (dataDE.obtenerUnDetalleexamen[0].Fecha);
          
            //let date = new Date(dataDE.obtenerUnDetalleexamen[0].Fecha);
     
            //let date = new Date(fechaExamen1);
            //console.log(fechaExamen1);
            //console.log(dataDE.obtenerUnDetalleexamen[0].Fecha);
            setfechaExamen(fechaExamen1);
            
            setdatosDE(dataDE.obtenerUnDetalleexamen[0]);
          }
        }
    }, []); 
    
    //console.log(datosDE);
    return (
      <Button key="brn_fec_puntos" color="success" disabled>
        {fechaExamen}   &nbsp; &nbsp; Puntos: {datosDE.NotaTotExa} 
      </Button>
    )
  }

  /* const MostrarFechaYnotaMaxima = () => {
    const [datosDE, setdatosDE] = useState([]);
    
      const QueryTipoExamen = gql`
      query obtenerDatosDetalleExamen($curso: Int!, $asignatura: Int!, $detallesistemaevaluacion: Int!) {
        obtenerUnDetalleexamen (curso: $curso, asignatura: $asignatura, detallesistemaevaluacion: $detallesistemaevaluacion) {
          Pk_DetalleExamen
          Fecha
          NotaTotExa
          Ponderacion
          DetalleSistemaEvaluacion {
            TipoExamen {
              CabeceraNotas
              clasetipoexamen
            }
          }
        }
      }`;
      const { loading, error, data } = useQuery(QueryTipoExamen, {
        variables: { curso: wcurso, asignatura: wasignatura, detallesistemaevaluacion: wdetallesistemaevaluacion},
      });
      
       React.useEffect(() => {
        if (data) {
          //console.log(data.obtenerUnDetalleexamen);
          if (data.obtenerUnDetalleexamen.length >0){
            //console.log("tiene algo");
            setNotatotal(data.obtenerUnDetalleexamen[0].NotaTotExa);
            //setPonderacion(data.obtenerUnDetalleexamen[0].Ponderacion);
            setCabeceraNotas(data.obtenerUnDetalleexamen[0].DetalleSistemaEvaluacion.TipoExamen.CabeceraNotas);
            //console.log(CabeceraNotas);
            setdatosDE(data.obtenerUnDetalleexamen[0]);
          }
        }
    }, [data]) 

      if (loading) 
        return (<Alert color="info">
          Cargando...
          </Alert>);
      
      if (error) return `Error! ${error.message}`;
      
      //const datosDE = data.obtenerUnDetalleexamen[0];
      
      //console.log(datosDE);
      //if (datosDE) {
       
        return (
          //datosDE.map(item => (
          <Button key="brn_fec_puntos" color="success" disabled>
            {datosDE.Fecha}    &nbsp; &nbsp; Puntos: {datosDE.NotaTotExa} 
          </Button>
          //))
        )
      //}
      //else {
       // return ""
      //}
  } */

  const MostrarComboExamen = () => {
      //onst data = ObtenerDatosAL();
      // const { loading, error, data } = useQuery({ query: QueryAnioLectivo, 
      //   variables: {
      //     curso: curso, asignatura: asignatura
      //   }
      // });
       //COMBO
      const QueryTipoExamen = gql`
      query obtenerDatosExamen($curso: Int!, $asignatura: Int!) {
        getDetalleexamen (curso: $curso, asignatura: $asignatura) {
          Pk_DetalleExamen
          DetalleSistemaEvaluacion {
            pk_DetalleSistemaEvaluacion
            TipoExamen{
              Pk_TipoExamen
              Descripcion
            }
          }	
        }
      }`;

      const { loading, error, data } = useQuery(QueryTipoExamen, {
        variables: { curso: wcurso, asignatura: wasignatura},
      });
      
      if (loading) return (<Alert color="info">
      Cargando...
      </Alert>);;
      
      if (error) return `Error! ${error.message}`;
      
      const datosAl = data.getDetalleexamen;
   
      //console.log(datosAl);
      return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} >
          <DropdownToggle caret color="primary" disabled={!deshabilitarCancelar}>
          {tipoExamentext}
          </DropdownToggle>          
          <DropdownMenu >
              {datosAl.map(item => (
                  <DropdownItem
                      key={item.DetalleSistemaEvaluacion.pk_DetalleSistemaEvaluacion}
                      value={item.DetalleSistemaEvaluacion.pk_DetalleSistemaEvaluacion} 
                      //value={item.DetalleSistemaEvaluacion.TipoExamen.Descripcion}
                      onClick={handleClick}
                  >
                  {item.DetalleSistemaEvaluacion.TipoExamen.Descripcion}
                  </DropdownItem>
              ))}
          </DropdownMenu>
       </ButtonDropdown>)
  }

  const handleClick = (e) => {
      //console.log(e.target.value);
      //var waniolectivo =  parseInt(e.target.value);

      settipoExamentext(e.target.innerText);
      //console.log(e.target);
      //console.log(e.target.value);
      var wdetallesiseva = parseInt(e.target.value);
      //console.log(wcurso);
      //console.log(wasignatura);
      
      //Obtener la nota máxima y la fecha del examen
      //MostrarFechaYnotaMaxima();

      //wdetallesistemaevaluacion = valor;
      //setDatos([]);
      setwdetallesistemaevaluacion(wdetallesiseva);
      MostrarDatosExamen();
      if (errorDE) {
        setMensajeError(errorDE)
        console.log("Error:", errorDE);
      } 
      loadAlumnos();
      if (error) {
        setMensajeError(error)
        console.log("Error:", error);
      }
      //console.log( winstitucion + " " + wpersona + " " + waniolectivo + " " + wasignatura + " " + wdetalleperiodo + " " + whorario + " " + wseccion + " " + waulacompartida  + " "  + wdetallesiseva);
      

      //refetch();
      //ObtenerDatos(wdetallesiseva);
      //console.log(wdetallesistemaevaluacion);
  } 

  //GRILLA
  const [datos, setDatos] = React.useState([]);
  const [originalData, setOriginalData] = React.useState([]);

  /* const ObtenerDatos = async  (wdetallesiseva) => {
      //console.log("aniolec " + waniolectivo);
      const QueryListaAsig = {
        query: `
          query obtenerdatos($institucion: Int!, $persona: Int!, $aniolectivo: Int!, $asignatura: Int!, $detalleperiodo: Int!, $horario: Int!, $seccion: Int!, $aulacompartida: String, $detallesistemaevaluacion: Int!) {
            notas_Alumnos(institucion: $institucion persona: $persona aniolectivo: $aniolectivo asignatura:$asignatura, detalleperiodo: $detalleperiodo, horario: $horario, seccion: $seccion, aulacompartida: $aulacompartida, detallesistemaevaluacion: $detallesistemaevaluacion) {

                pk_Notas
                detallealumnosinscriptos{
                  Examen{
                    Pk_Examen
                  }
                  InstitucionPersona{
                    Persona{
                      pk_persona
                      Nrodocumento
                      Nombre
                      Apellido
                    }
                  }
                }
                getnotasdetalles(detallesistemaevaluacion:$detallesistemaevaluacion) {
                  pk_NotasDetalles
                  Nota
                  NotaDocente
                }
            }
          }
        `,
        variables: {
          institucion: winstitucion,
          persona: wpersona,
          aniolectivo: waniolectivo,
          asignatura: wasignatura,
          detalleperiodo: wdetalleperiodo,
          horario:whorario,
          seccion:wseccion,
          aulacompartida:waulacompartida,
          detallesistemaevaluacion:wdetallesiseva,

        },
      };
      //console.log(QueryListaAsig);
      const response = await  fetch(serverurl, {
          method: 'POST',
          body: JSON.stringify(QueryListaAsig),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + getAccessToken()
          }  
        },
      )

      const body = await response.json()
      //console.log("TIK" + JSON.stringify(body));
      const datos1 = await body.data.notas_Alumnos
      //Cambiamos la estructura de los datos por que no funciona correctamente cuando los datos a modificar estan 
      //dentro de otro array o son detalles de otro campo ej.: detallealumnosinscriptos.asignatura o item.getnotasdetalles[0].NotaDocente
      
      var NotafinalCargada = false;

      let tableData = []
      datos1.forEach((item) => {
        let object = {
          //select and modify data from incoming server data
          pk_Notas : item.pk_Notas,
          //Estos coloco como estaban por que no se van a modificar en la grilla
          detallealumnosinscriptos: item.detallealumnosinscriptos,
          //estos si cambio a la primera linea por que si se van a modificar en la grilla
          pk_NotasDetalles: item.getnotasdetalles[0].pk_NotasDetalles,
          
          Nota: item.getnotasdetalles[0].Nota,
          NotaDocente: item.getnotasdetalles[0].NotaDocente,
          //NotaDocenteOriginal: item.getnotasdetalles[0].NotaDocente
        }
        if (object.Nota) {
          NotafinalCargada = true;
        }
        
        tableData.push(object)
      })
      
      //console.log(tableData);
      //Habilitamos el boton de edición
      if (datos1.length>0){
        if (NotafinalCargada) {
          setMensajeNota(true)
          //setDisableEdit(true)
        }
        else{
          setMensajeNota(false)
          //setDisableEdit(false)
        }
      } 
      else{
        setMensajeNota(false)
        //setDisableEdit(true)
      }
      //console.log("TIK" + JSON.stringify(datos));
      setDatos(tableData);
      //console.log("datos" +datos);
      setOriginalData(tableData);
      //console.log("Ejecuto la BUSQUEDA");
      //console.log("original" + originalData);
  } */

  // declaramos la bandera de edición
  const [editable, setEditable] = React.useState(false);

  const [skipPageReset, setSkipPageReset] = React.useState(false);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
 
    setDatos(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
      setSkipPageReset(false)
  }, [datos])

  // const resetData = () => {
  //   //console.log(originalData);
  //   //setDatos([]);
  //   setDatos(originalData);
  // }

  const columns = React.useMemo(() => {
    // Create an editable cell renderer

    const EditableCell = ({
      value: initialValue,
      row: Row,
      column: { id },
      editable,
      updateMyData, // This is a custom function that we supplied to our table instance
    }) => {
      const {index} = Row;
      const {original} = Row;
      console.log("valor inicial" + initialValue);
      //console.log(rows[index].original.Nota);
      //console.log(Row);
      //console.log(original.Nota);
      //console.log(rows);
      
      //console.log("NOTA TOTAL" + Notatotal);

      var Editar = true;
      //if (!original.Nota === undefined || !original.Nota === null) {

      //if (!rows[index].values.Nota === undefined || !rows[index].values.Nota === null) {
      
      //console.log(original.pk_NotasDetalles);
      //SI YA TIENE CARGADA LA NOTA, VALIDAR QUE NO SE PUEDA MODIFICAR SI YA PASARON 8 DÍAS
      //if (original.pk_NotasDetalles !== '0'){
      //console.log(original.NotaOriginal);
      if (original.NotaOriginal !== undefined && original.NotaOriginal !== null && original.NotaOriginal !== '') {
        let fechaCargaDate = new Date(original.Fecha +" 00:00:00:00");
        // Add days
        fechaCargaDate.setDate(fechaCargaDate.getDate() + 8);
        //console.log(fechaCargaDate);
        //console.log(fechaActual);
        let fechaActualDate = new Date(fechaActual+" 00:00:00:00");
        //console.log(fechaActualDate);
        //console.log("fecha ACTUAL " + fechaActualDate);
        //console.log("fecha EXAMEN " + fechaCargaDate);
        if (fechaActualDate > fechaCargaDate) {
          Editar = false;
        }
        
      }
      //if (original.Nota !== undefined && original.Nota !== null){
      //    Editar = false;
      //}
      
      // We need to keep and update the state of the cell normally
      const [value, setValue] = React.useState(initialValue);
      //console.log(value);
      const [nombreError, setNombreError] = React.useState('');
      //const [nameError, setNameError] = useState({ error: false, label: '', helperText: '', }); 

      const onChange = (e) => {
        if (id === 'Nota') {
          //console.log(Notatotal);
          //if (e.target.value > 100) {
          if (e.target.value > Notatotal) {
            setNombreError('No > '+ Notatotal)
            //setValue(100)
          }
          else {
            //setNombreError('');        
           setValue(e.target.value)
          } 
        }
        else{
          setValue(e.target.value)
        }
        
        // validaciones(e.target.value);
        // if (nombreError==='') {
        //   setValue(e.target.value)
        // }
      }
      
      //Onblur se activa cuando el usuario retira el foco de la aplicación de un elemento de la página
      // We'll only update the external data when the input is blurred
      const onBlur = () => {
        //if (nombreError==='') {
        updateMyData(index, id, value, columns)
        //}
        setNombreError('');
      }
      
      // If the initialValue is changed external, sync it up with our state
      React.useEffect(() => {
          setValue(initialValue)
      }, [initialValue])

      const valorNumerico = (value) => {
        if (typeof value === 'number') {
          //console.log ('Es numero');
          //it's a number
          return value;
        }
        return '';
      }


      let retObj = null;
      if (editable && Editar) {
          switch (id) {
              case 'temaTesis':
                retObj = <Input type="text"  value={value || ''} onChange={onChange} onBlur={onBlur} onWheel={ event => event.currentTarget.blur() } />;
                break;
              default:
                retObj = <div> 
                            <Input type="number" style={{ height: '28px', textAlign: "center" }} min="0" max={Notatotal || '100'} value={value || ''} onChange={onChange} onBlur={onBlur} onWheel={ event => event.currentTarget.blur() } /> 
                            <h6 className="text-danger" style={{ textAlign: "center" }}>{nombreError}</h6>
                          </div>;
                break;
          }
      } else {
          switch (id) {
              case 'temaTesis':
                //console.log(value);
                retObj = <Input type="text" style={{ textAlign: "left" }} value={value || ''} onChange={onChange} onBlur={onBlur} disabled />
                break;
              default:
                retObj = <Input type="number" style={{ height: '28px', textAlign: "center" }} value={valorNumerico(value)} onChange={onChange} onBlur={onBlur} disabled />
                break;
          }
      }
      //console.log(editable);
      //return <Input maxlength="3" value={value} onChange={onChange} onBlur={onBlur} />
      //<h6 className="text-danger">xxxx</h6>
      //<Input type="number" min="0" max="100" value={value} onChange={onChange} onBlur={onBlur} /> 
      //onWheel={ event => event.currentTarget.blur() }} //deshabilita el scroll dentro del input
      return retObj;
        /* return (
          editable ? 
            Editar ?  
              <div> 
                <Input type="number" style={{ textAlign: "center" }} min="0" max={Notatotal || '100'} value={value || ''} onChange={onChange} onBlur={onBlur} onWheel={ event => event.currentTarget.blur() } /> 
                <h6 className="text-danger">{nombreError}</h6>
              </div>
            : <Input type="number" style={{ textAlign: "center" }} value={valor(value)} onChange={onChange} onBlur={onBlur} disabled />
          : <Input type="number" style={{ textAlign: "center" }} value={valor(value)} onChange={onChange} onBlur={onBlur} disabled />)   */
    }
    
    var arrayColumnas = [
      /* {
        Header: 'pk_Notas',
        accessor: 'pk_Notas', // accessor is the "key" in the data
      }, */
      {
        Header: 'PK_Notasdet',
        //accessor: 'detallealumnosinscriptos.InstitucionPersona.Persona.pk_persona',
        accessor: 'pk_NotasDetalles', // accessor is the "key" in the data
        
        //disableFilters: true,
        //Cell: EditableCell
      },  
      {
        Header: <div style={{ textAlign: "center" }}>Matrícula</div>,
        //accessor: 'detallealumnosinscriptos.InstitucionPersona.Persona.pk_persona',
        accessor: 'detallealumnosinscriptos.InstitucionPersona.Persona.pk_persona', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: <div style={{ textAlign: "center" }}>Nro. Doc.</div>,
        accessor: 'detallealumnosinscriptos.InstitucionPersona.Persona.Nrodocumento',
        Filter: DefaultColumnFilter,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
        //disableFilters: true
      },
      {
        Header: 'Apellido',
        accessor: 'detallealumnosinscriptos.InstitucionPersona.Persona.Apellido',
        Filter: DefaultColumnFilter,
        //disableFilters: true
      },
      {
        Header: 'Nombre',
        accessor: 'detallealumnosinscriptos.InstitucionPersona.Persona.Nombre',
        Filter: DefaultColumnFilter,
      },
       /* {
        Header: 'NotaDocente',
        accessor: 'NotaDocente',
      },  */
      {
         Header: <div style={{ textAlign: "center" }}>Nota</div> ,
         accessor: 'Nota', 
         Filter: DefaultColumnFilter,
         Cell: EditableCell
      },
      /*{
        Header: <div style={{ textAlign: "center" }}>Estado</div> ,
        accessor: 'Estado', 
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: <div style={{ textAlign: "center" }}>Fecha</div> ,
        accessor: 'Fecha', 
    
     } ,*/
      
    ]
      //si es tesis agregamos una columna mas para la carga del tema de tesis
      if (EsTesis===1) {
        //if (ClaseTipoexamen>1){
          arrayColumnas.push({
          Header: <div style={{ textAlign: "center", width: '200px' }}>Tema de tesis</div> ,
          accessor: 'temaTesis', 
          Filter: DefaultColumnFilter,
          Cell: EditableCell});
        //}
      }      
    return arrayColumnas}, [Notatotal, EsTesis, fechaActual]
  )
  
  async function validar(item)  {
    let wnotas = parseInt(item.pk_Notas);
    let wNombreAlu = item.detallealumnosinscriptos.InstitucionPersona.Persona.Nombre + " " + item.detallealumnosinscriptos.InstitucionPersona.Persona.Apellido;
      
    let wresultado = await obtenerExamenExcluyenteRendido({ variables: { 
      notas: wnotas,
      detallesistemaevaluacion: wdetallesistemaevaluacion,}});
    if (errorEA) {
        setMensajeError(errorEA);
        console.log("Error:", errorEA);
    } 
    //console.log("RESULTADO" +  JSON.stringify(wresultado));
    if (wresultado.data.obtenerExamenExcluyenteRendido.length > 0){
      const examenRendido = wresultado.data.obtenerExamenExcluyenteRendido[0].Descripcion;
      setMensajeError("No se guardaron las notas, debido a que el alumno " +  wNombreAlu + ". No puede rendir este examen por que rindio el " + examenRendido + "");
      //console.log("No puede rendir este examen por que rindio el " + examenRendido + "");
      return true;
    }
    
    //'Validar que el alumno tenga procesada la solicitud de examen extraordinario
    //console.log("clase" + ClaseTipoexamen)
    let wArancel = 18; //'derecho a examen
    let wcurso = parseInt(item.Curso.Pk_Curso);
    let wasignatura = parseInt(item.Asignatura);
    let wDetalleSistemaEvaluacion = parseInt(datosDE.DetalleSistemaEvaluacion.pk_DetalleSistemaEvaluacion);
    let wpersona = parseInt(item.detallealumnosinscriptos.InstitucionPersona.Persona.pk_persona);
    let wexamen = parseInt(item.detallealumnosinscriptos.Examen.Pk_Examen);
    //console.log(wpersona + " - " + wcurso + " - " + wasignatura+ " - " + wDetalleSistemaEvaluacion);

    if (ClaseTipoexamen > 1) { //'parciales extraordinarios, finales de recuperación, sustitutivo y finales extraordinarios
      
      //if(tipoExamentext !== "Final") {
      //console.log(pkTipoexamen);
      if(pkTipoexamen !== 5) {
        //console.log('entro a validar');
        let wresultado = await obtenerDerechoAexamenProcesado({ variables: { 
          Arancel: wArancel,
          Curso: wcurso,
          Asignatura: wasignatura,
          DetalleSistemaEvaluacion: wDetalleSistemaEvaluacion,
          Persona: wpersona,
          Examen:wexamen}});
        if (errorDAP) {
            setMensajeError(errorDAP);
            console.log("Error:", errorDAP);
        }  

        //console.log("RESULTADO" +  JSON.stringify(wresultado.data.obtenerDerechoAexamenProcesado));
        if (wresultado.data.obtenerDerechoAexamenProcesado.length > 0){
          let wMontodebe = wresultado.data.obtenerDerechoAexamenProcesado[0].MontoDebe;
          let wMontoHaber = wresultado.data.obtenerDerechoAexamenProcesado[0].MontoHaber;
          //console.log(wMontodebe );
          if (wMontodebe !== wMontoHaber) {
            setMensajeError("No se guardaron las notas, debido a que el alumno " +  wNombreAlu + ". Proceso la solicitud de " + tipoExamentext + " pero no realizo el pago correspondiente.");
            return true;
          }
        }
        else {
          setMensajeError("No se guardaron las notas, debido a que el alumno " +  wNombreAlu + ". No proceso la solicitud de " + tipoExamentext);
          return true;
        }
      }
      else {   //'si el tipo de examen es final
        //'valida que no tenga una solicitud procesada
        let wresultado = await obtenerExistenciaDerechoAexamen({ variables: { 
          Arancel: wArancel,
          Curso: wcurso,
          Asignatura: wasignatura,
          DetalleSistemaEvaluacion: wDetalleSistemaEvaluacion,
          Persona: wpersona,
          Examen:wexamen}});
        if (errorEDA) {
            setMensajeError(errorEDA);
            console.log("Error:", errorEDA);
        }
          
        //console.log("RESULTADO" +  JSON.stringify(wresultado.data.obtenerExistenciaDerechoAexamen));
        if (wresultado.data.obtenerExistenciaDerechoAexamen.length > 0){
          const examenRendido = wresultado.data.obtenerExistenciaDerechoAexamen[0].Descripcion;
          setMensajeError("No se guardaron las notas, debido a que el alumno " +  wNombreAlu + ". Tiene procesada la solicitud de " + examenRendido + "");
          //console.log("No puede rendir este examen por que rindio el " + examenRendido + "");
          return true;
        }
      }
    }
    else {
      //'valida que no exista solicitud de derecho a examen para los exámenes excluyentes correspondientes al examen que se carga
      //'la solicitud de derecho a examen para el examen que lo excluye
      //console.log(datosDE.DetalleSistemaEvaluacion.TipoExamen.Pk_TipoExamen);
      let wTipoExamen = parseInt(datosDE.DetalleSistemaEvaluacion.TipoExamen.Pk_TipoExamen);
      let wresultado = await obtenerExamenesExcluyentes({ variables: { 
        Arancel: wArancel,
        Curso: wcurso,
        Asignatura: wasignatura,
        TipoExamen: wTipoExamen,
        Persona: wpersona}});
      if (errorEE) {
          setMensajeError(errorEE);
          console.log("Error:", errorEE);
      }
      //console.log("RESULTADO" +  JSON.stringify(wresultado.data.obtenerExamenesExcluyentes));
      if (wresultado.data.obtenerExamenesExcluyentes !== "") {
        setMensajeError("No se guardaron las notas, debido a que el alumno " +  wNombreAlu + ". " + wresultado.data.obtenerExamenesExcluyentes);
        return true;
      }
      
    }
    //'Valida modificaciones en asignaturas aprobadas
    let wEstado = item.Estado;
    if (wEstado === "A") {
      //Agregar el alumno al array para mostrar un mensaje con la lista
      AlumnosMateriasAprobadas.push(wNombreAlu);
    }

    return false;
  }

  async function recorrerYGuardar() {
    //console.log("llego");
        for (const item of datos) {
          //console.log(JSON.stringify(item));
          const wexamen = parseInt(item.detallealumnosinscriptos.Examen.Pk_Examen);
          const temaTesis = item.temaTesis;
          const waniolectivo = parseInt(item.Curso.AnioLectivo);
          const wPensumCarrera = parseInt(item.Curso.PensumCarrera);
          const wDetalleAlumnosInscriptos = parseInt(item.detallealumnosinscriptos.Pk_detalleAlumnosInscriptos);
          const wnotas = parseInt(item.pk_Notas);
          const wpknotasdetalles = parseInt(item.pk_NotasDetalles);
          var wnotaOriginal = parseFloat(item.NotaOriginal);
              //console.log ("NOTA ORIGIANL " + wnotaOriginal);

          if (item.pk_NotasDetalles === '0') {
            //Solo Agregar
            if(typeof item.Nota !=='undefined' && item.Nota !==null && item.Nota !=='') {
              var wnotaNueva = parseFloat(item.Nota);
              await agregarNota({ variables: { 
                  notas: wnotas,
                  detallesistemaevaluacion: wdetallesistemaevaluacion,
                  examen: wexamen,
                  notaNueva: wnotaNueva,
                  temaTesis: temaTesis,
                  CabeceraNotas:CabeceraNotas,
                  AnioLectivo: waniolectivo,
                  PensumCarrera: wPensumCarrera,
                  DetalleAlumnosInscriptos: wDetalleAlumnosInscriptos,
                  pk_NotasDetalles: wpknotasdetalles,
                  notaOriginal: wnotaOriginal
                },});
                //const  { error } = useMutation(Query);
                if (errorAgrNota) {
                  setMensajeError(errorAgrNota)
                  console.log("Error:", errorAgrNota);
                  //return <p>Error al agreagr la nota</p>;
                } 
            }
          }
          else {
              //Si ya existe id de NotasDetalles Modificar
              if(typeof item.Nota =='undefined' || item.Nota == null || item.Nota ==='') {
                //console.log("ES uno de los tres");
                wnotaNueva = null;
              }
              else {
                wnotaNueva = parseFloat(item.Nota);
              }
              
              wnotaOriginal = parseFloat(item.NotaOriginal);
              if (wnotaNueva!==wnotaOriginal) {
                
                await agregarNota({ variables: { 
                  notas: wnotas,
                  detallesistemaevaluacion: wdetallesistemaevaluacion,
                  examen: wexamen,
                  notaNueva: wnotaNueva,
                  temaTesis: temaTesis,
                  CabeceraNotas:CabeceraNotas,
                  AnioLectivo: waniolectivo,
                  PensumCarrera: wPensumCarrera,
                  DetalleAlumnosInscriptos: wDetalleAlumnosInscriptos,
                  pk_NotasDetalles: wpknotasdetalles,
                  notaOriginal: wnotaOriginal
                },});
                //const  { error } = useMutation(Query);
                if (errorAgrNota) {
                  setMensajeError(errorAgrNota)
                  console.log("Error:", errorAgrNota);
                  //return <p>Error al agreagr la nota</p>;
                } 
              }
           
          }
        }
  }

  async function makeEditable()  {
      //setEditable(false);
      //console.log(editable);
      

      let wnotaNueva;
      if (editable) {
        setMensajeError();
        setMensajeNota();

        let valida = false;
        //Validar
        //'Validar que el alumno haya rendido los exámenes adecuados
        for (const item of datos) {
          if (item.pk_NotasDetalles === '0') {
            //Solo Agregar
            if(typeof item.Nota !=='undefined' && item.Nota !==null && item.Nota !=='') {
              valida = await validar(item);
              //console.log(valida);
              if (valida) {
                return true
              } 
            }
          }
          else {
            //Si ya existe id de NotasDetalles Modificar
            if(typeof item.Nota == 'undefined' || item.Nota == null || item.Nota === '') {
              //console.log("ES uno de los tres");
               wnotaNueva = null;
            }
            else {
               wnotaNueva = parseFloat(item.Nota);
            }
            
            var wnotaOriginal = parseFloat(item.NotaOriginal);
            if (wnotaNueva!==wnotaOriginal) {
              valida = await validar(item);
              if (valida) {
                return true
              } 
            }
          }
        }
        //console.log(JSON.stringify(AlumnosMateriasAprobadas));
        if (AlumnosMateriasAprobadas.length >0){
          //setMensajeNota(AlumnosMateriasAprobadas);
          setAlumnosMA(AlumnosMateriasAprobadas);
          handleShowMensaje();
          return true;
        } 
        else {
          await recorrerYGuardar();
        }
      }
      setEditable(!editable);
      setDeshabilitarCancelar(!deshabilitarCancelar);
      return false;
  }

  async function handleSaveMensaje() {
    setshowMensaje(false);
    await recorrerYGuardar();
    setEditable(!editable);
    setDeshabilitarCancelar(!deshabilitarCancelar);
    loadAlumnos();
    if (error) {
      setMensajeError(error)
      console.log("Error:", error);
    }
    setMensajeError("");
  }

  async function EditarYmostrar()  {
    //Si la grilla tiene datos 
    if (datos.length >0) {
      // Validar que no hayan pasado 30 días desde que se rindió el examen 
      let fechaExamenDate = new Date(fechaExamen+" 00:00:00:00");
      // Add days
      console.log("DIAS permitidos para carga " + diasParaCargaWeb);
      fechaExamenDate.setDate(fechaExamenDate.getDate() + diasParaCargaWeb)
      //console.log(fechaExamenDate);
      //console.log(fechaActual);
      let fechaActualDate = new Date(fechaActual+" 00:00:00:00");


      //console.log("fecha ACTUAL " + fechaActualDate);
      //console.log("fecha EXAMEN " + fechaExamenDate);
      //if (Date.parse(fechaActual) >= Date.parse(fechaExamen)) {
      if (fechaActualDate > fechaExamenDate) {
        //console.log("FECHA ACTUAL ES MAYOR ");
        //alert("Edición de notas deshabilitada, debido a que ya transcurrieron 30 días desde la fecha del examen.")
        setMensajeNota("Edición de notas deshabilitada, debido a que ya transcurrieron 30 días desde la fecha del examen.")
      }
      else {
        //console.log("ES MENOR O IGUAL");
        const validar = await makeEditable();
        if (!validar) {
          loadAlumnos();
          if (error) {
            setMensajeError(error)
            console.log("Error:", error);
          }
          setMensajeError("");
        } 
      }
    }
  }

  const [showMensaje, setshowMensaje] = useState(false);
  const handleCloseMensaje = () => {
    setshowMensaje(false);
  };
  const handleShowMensaje = () => setshowMensaje(true);

 /*  function wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
  } */

  function cancelarEditable()  {
    setEditable(false);
    setDeshabilitarCancelar(true);
    //setDatos([]);
    //console.log(wdetallesistemaevaluacion);
    setDatos(originalData);
    setMensajeError();
    //refetchAlumnos();
  }

  //Obtenemos los datos de la grilla 
  //use effect ejecuta obtener datos
  //si queremos que se ejecute solo una vez se agrega corchetes como segundo parametro
  //si queremos que se ejecute cuando una variable de estado cambia se le pasa ese dato como segundo parametro 
  // useEffect(() => {
  //   //setDatos([]);
  //   //setDatos([]);
  //   if (wdetallesistemaevaluacion !== 0) {
  //     ObtenerDatos();
  //   }
  // }, [wdetallesistemaevaluacion]) // don't forget this empty bracket it indicates the function will only run once when the component will load initially
  const MostrarMensajeNota = () => {
      return (
        //!MensajeNota ? "":<Alert color="warning"> Si desea modificar una nota ya publicada, favor comunicarse con secretaría.</Alert>
        !MensajeNota ? "":<Alert color="warning">{MensajeNota}</Alert>
        )
  }

  const MostrarError = () => {
      return (
        !MensajeError ? "":<Alert color="alert alert-danger"> {MensajeError}</Alert>
        )
  }

  /* //Controlamos si data esta cargada y si lo está seteamos los datos 
  useEffect(() => {
      if(error){
        // handle error here
        console.log(error);
      }
      if(data) {
        
      }
    }, [data], [error]);

    if (loading) return <Alert color="info">
          Cargando...
        </Alert>
    if (error) {
      console.log(error.message)
      return (
        <Alert color="danger">
          {error.message}
        </Alert>
      )
  } */

  function Volver()  {
    history.goBack();
    history.push({
      pathname: '/notas',
        state: {aniolectivoPos: waniolectivo} // your data array of objects
    })
    // navigate({
    //   pathname: '/notas',
    //     state: {aniolectivoPos: waniolectivo} // your data array of objects
    // });
  }
  const initialState = { hiddenColumns: ['pk_NotasDetalles'] };
    return (
        <Container>
            <Form>
                <Row>
                  <Col >
                    <FormGroup>
                      <MostrarComboExamen></MostrarComboExamen>
                      &nbsp;
                      <Button color="info" onClick={Volver}>
                        Volver
                      </Button>
                      &nbsp;
                     
                     <Button color="primary" onClick={EditarYmostrar}>{editable ? "Guardar" : "Editar"}</Button>
                     {/* comentarios  <Button color="primary" onClick={makeEditable} disabled={disableEdit}>{editable ? "Guardar" : "Editar"}</Button> */}

                      &nbsp;
                      <Button onClick={cancelarEditable} disabled={deshabilitarCancelar}>Cancelar</Button>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      <Button color="success" disabled>{asigdescrip}</Button> 
                      &nbsp;
                      <MostrarFechaYnotaMaxima2></MostrarFechaYnotaMaxima2>
                    </FormGroup>
                  </Col>
                </Row>
                &nbsp;
                <MostrarMensajeNota></MostrarMensajeNota>
                <MostrarError></MostrarError>
            </Form>

            <TableContainer 
              columns={columns} 
              data={datos}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
              editable={editable}
              initialState= {initialState}
              />
              
            <Modal show={showMensaje} onHide={handleCloseMensaje}>
              <Modal.Header>
                <Modal.Title>Los siguientes alumnos</Modal.Title>
              </Modal.Header>
              <Modal.Body>

              {AlumnosMA.map(item => (
                <li key={item}> {item}</li>
                
              ))}
              <hr></hr>
              <h6>Ya aprobaron la asignatura. Desea guardar de todas formas ?</h6>
              </Modal.Body>
              <Modal.Footer>
                <Button color="secondary" data-bs-dismiss="modal" onClick={handleCloseMensaje}>Cancelar</Button>
                <Button color="primary" onClick={handleSaveMensaje}>
                  Aceptar
                </Button>
              </Modal.Footer>
            </Modal>

        </Container>
    )
 }  
 export default Notas_page