// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-dropdown-login {
  width: 100%;
  margin-top: 4px;
  text-align: left;
}
.my-dropdown {
    width: 100%;
    text-align: center;
  }
.grilla-dropdown {
    width: 100%;
    text-align: left;
  }
.my-boton-imprimir {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }
  .my-daterange{
    display: flex;
    justify-content: center;
  }
  .MuiDialog-root{
    width: '500px';
  }
  .MuiDialog-container{
    width: '500px';
  }`, "",{"version":3,"sources":["webpack://./src/css/styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;AACA;IACI,WAAW;IACX,kBAAkB;EACpB;AACF;IACI,WAAW;IACX,gBAAgB;EAClB;AACF;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;EACpB;EACA;IACE,aAAa;IACb,uBAAuB;EACzB;EACA;IACE,cAAc;EAChB;EACA;IACE,cAAc;EAChB","sourcesContent":[".my-dropdown-login {\r\n  width: 100%;\r\n  margin-top: 4px;\r\n  text-align: left;\r\n}\r\n.my-dropdown {\r\n    width: 100%;\r\n    text-align: center;\r\n  }\r\n.grilla-dropdown {\r\n    width: 100%;\r\n    text-align: left;\r\n  }\r\n.my-boton-imprimir {\r\n    width: 100%;\r\n    margin-top: 20px;\r\n    text-align: center;\r\n  }\r\n  .my-daterange{\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n  .MuiDialog-root{\r\n    width: '500px';\r\n  }\r\n  .MuiDialog-container{\r\n    width: '500px';\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
