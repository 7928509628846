import React from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import { Table } from 'reactstrap';
//import Table from 'react-bootstrap/Table';
import { Filter, DefaultColumnFilter } from './filters';
//import MaterialReactTable from 'material-react-table';

const TableContainer = ({ columns, data, updateMyData, skipPageReset, editable, initialState }) => {

  // const defaultColumn = React.useMemo(
  //   () => ({
  //     minWidth: 30,
  //     width: 150,
  //     maxWidth: 400,
  //     filter: DefaultColumnFilter,
  //   }),
  //   []
  // )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    // use the skipPageReset option to disable page resetting temporarily
    autoResetPage: !skipPageReset,
    // updateMyData isn't part of the API, but
    // anything we put into these options will
    // automatically be available on the instance.
    // That way we can call this function from our
    // cell renderer!
    updateMyData,
    //defaultColumn,
    defaultColumn: { Filter: DefaultColumnFilter },
    editable,
    initialState,
    //initialState:{ hiddenColumns: ['Curso.Pk_Curso'] }
  }, useFilters, useSortBy);
  
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };
  //console.log(data);
  
  return (
    //<Table striped bordered hover size="sm" {...getTableProps()}>
    <Table bordered hover size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              //Basico
              //<th {...column.getHeaderProps()}>{column.render('Header')}</th>

              //Ordenar por
              //<th {...column.getHeaderProps(column.getSortByToggleProps())}>
              //  {column.render("Header")}
              //  {generateSortingIndicator(column)}
              //</th>
              
              //Filtrar por
              <th {...column.getHeaderProps()}>
              <div {...column.getSortByToggleProps()}>
                {column.render("Header")}
                {generateSortingIndicator(column)}
              </div>
              <Filter column={column} />

            </th>

            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          //console.log(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TableContainer;