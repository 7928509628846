import React from 'react';

//import MaterialReactTable from 'material-react-table';
//Import Material React Table Translations
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { MRT_Localization_ES } from 'material-react-table/locales/es';

const MaterialTable = ({ columns, data, enableExpanding, getSubRows, enableExpandAll, updateMyData, skipPageReset, editable }) => {
  //should be memoized or stable
  return <MaterialReactTable columns={columns} data={data} 
  enableExpanding = {enableExpanding}
  //enableExpandAll = {enableExpandAll}
  getSubRows = {getSubRows}
  localization={MRT_Localization_ES}/>;
};

export default MaterialTable;