import React from 'react';
import PropTypes from 'prop-types';
//import { useQuery } from 'react-apollo';
import { gql, useQuery } from '@apollo/client';

//import gql from 'graphql-tag';
//import { Alert } from 'reactstrap';
import Alert from 'react-bootstrap/Alert';
//import GET_USER from './getUser.graphql';

const UserContext = React.createContext({});

const GET_USER = gql`query {
    usuarioLogueado {
      pk_usuario
      fkpersona {
        pk_persona
        Nombre
        Apellido
        personatipopers {
          perfilweb
          TipoPersona {
            Pk_tipopersona
          }
        }
      }
      User
    }
}`;

export const UserProvider = props => {
  const { error, loading, data } = useQuery(GET_USER, 
    {fetchPolicy: "network-only"});

  if (loading) return 'Loading...';
    
    if (error) {
      console.log(error.message)
      return (
        <Alert color="danger">
          {error.message}
        </Alert>
      )
    }
  //const user = loading || !data.usuarioLogueado ? null : data.User;
  const user = data.usuarioLogueado;
  //console.log("CONTEXT " + JSON.stringify(user));
  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;
export default UserContext;

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};